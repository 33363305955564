import React, { useState, useEffect }   from 'react';
import { useSelector } from 'react-redux';
import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import { TextInput, CheckBox, NumberInput, FormHeader, Modal, PaymentInfo, CancelButton, ConfirmButton, SimpleDropdown, WalletDropdown, CurrencyDropdown, LifetimeDurationDropdown, LoginMethodDropdown, IconDropdown, NumberInputWithConverter, SubscriptionPlanList } from '../components/FlashForms/comp/InputsLib.js';

import classnames from 'classnames';

import { ArrowLongLeftIcon, EyeOffIcon, ClipboardIcon, CheckIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { FaRegEye } from 'react-icons/fa6'; 

const items = [
    {
        'name': 'shopify',
        'svg': 'Shopify.png',
    },
    {
        'name': 'woocommerce',
        'svg': 'Woocommerce.png',
    }
];

const ECommercePage = () => {

    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
    const userPublicKey = localStorage.getItem('userHexPublicKey') || '';
    const userPrivateKey = localStorage.getItem('userHexPrivateKey') || '';

    const wallets = useSelector((state) => state.walletReducer.wallets);

    const [apiKey, setApiKey] = useState('');
    const [isApiKeyExists, setIsApiKeyExists] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const [isLoading, setIsLoading] = useState();

    // Api key status message
    const [apiKeyStatusMessage, setApiKeyStatusMessage] = useState('');

    // wallet
    const [selectedWalletId, setSelectedWalletId] = useState();

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(apiKey);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000)
    }

    const handleVisibleChange = () => {
        setIsVisible(!isVisible);
    }   

    const handleWalletChange = (e) => {
        const selectedWalletId = e.target.value;
        const selectedWallet = wallets.find((wallet) => wallet.id.toString() == selectedWalletId);
        if (selectedWallet) {
            setSelectedWalletId(selectedWalletId);
        }
    }

    const generateOrUpateApiKey = async () => {
        const formData = new FormData();
        formData.append('user_public_key', userPublicKey);
        formData.append('service', selectedItem);

        try {
            const response = await fetch('https://api.paywithflash.com/ecommerce/api/generate_or_update_api_key', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(error);
            }

            const data = await response.json();

            if (data.success && data.data.api_key) {
                setApiKey(data.data.api_key);
                setIsApiKeyExists(true);
                setApiKeyStatusMessage(data.message);
            }
            return data;
        } catch (error) {
            console.error(error);
            setApiKeyStatusMessage('Network was not ok');
            // throw new Error(error);
        }
    }

    const fetchData = async() => {
        const formData = new FormData();
        formData.append('user_public_key', userPublicKey);
        formData.append('service', selectedItem);

        try {
            const response = await fetch('https://api.paywithflash.com/ecommerce/api/fetch_data_with_user_public_key', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network was not ok');
            }

            const data = await response.json();

            if (data.success) {
                setIsApiKeyExists(true);
                if (data.data.wallet_id) setSelectedWalletId(data.data.wallet_id);
                setApiKey(data.data.api_key);
            }
            else {
                setIsApiKeyExists(false);
                setApiKeyStatusMessage(data.error);
            }
        } catch (error) {
            console.error(error);
            setApiKeyStatusMessage('Network was not ok');
            // throw new Error(error);
        }
    }

    useEffect(() => {
        if (selectedItem) {
            fetchData();
        }
    }, [selectedItem]);

    useEffect(() => {
        setTimeout(() => {
            setApiKeyStatusMessage('');
        }, 2000)
    }, [apiKeyStatusMessage])

    useEffect(() => {
        setIsLoading(true);
        if (wallets.length > 0) {
            setSelectedWalletId(wallets[0].id);
            setIsLoading(false);
        }
    }, [wallets]);

    useEffect(() => {
        if (isApiKeyExists && selectedWalletId) {
            const formData = new FormData();
            formData.append('user_public_key', userPublicKey);
            formData.append('wallet_id', selectedWalletId);
            formData.append('service', selectedItem)
            
            try {
                fetch('https://api.paywithflash.com/ecommerce/api/update_data_for_connection', {
                    method: 'POST',
                    body: formData,
                })
                .then (response => {
                    if (!response.ok) {
                        throw new Error('Network was not ok');
                    }
                })
                .then (data => {

                });
            } catch (error) {
                console.error(error);
                throw new Error(error);
            }
        }
    }, [isApiKeyExists, selectedWalletId])

    return (
        <>
            { isLoading && (
                <div className="loading-container absolute inset-0 flex items-center justify-center bg-black bg-opacity-60" style = {{ zIndex : '100'}}>
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12 mb-4"></div>
                </div>
            )}
            {/* { !isLoading && ( */}
                <div className="min-h-screen">
                    <SidebarLayout>
                    <Header
                        title="E-commerce plugins"
                        subtitle=""
                        showWalletsAvatar={false} // Set this to true or false as needed
                    />
                    <div className='p-8'>
                        <div className="grid md:grid-cols-6 gap-6 sm:grid-cols-1 gap-1">
                            { items.map((item, index) => (
                                <div 
                                key={index} 
                                className={ classnames("w-full p-4 mx-4 flex flex-col items-center rounded-lg cursor-pointer hover:shadow-lg transition-300 ease-in-out transform hover:-translate-y-1", { '-translate-y-1 shadow-lg': item.name == selectedItem })} 
                                onClick={(e) => setSelectedItem(item.name)}>
                                    <img src={ item.svg } />
                                    <div className="text-center font-semibold mt-2"> { item.name } </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    { selectedItem && (
                        <>
                            <div className="p-8">
                                <div className="divider"></div>
                            </div>
                            <div className="px-8 w-2/3">
                                <WalletDropdown label="" id="wallet" name="wallet" wallets={wallets} value={selectedWalletId} onChange={handleWalletChange} />
                            </div>
                            <div className="p-8">
                                <div className="flex flex-row mb-4">
                                    <input
                                    type="text"
                                    rows={1}
                                    name="APIKey"
                                    id="APIKey"
                                    disabled={true}
                                    placeholder="API key appears here"
                                    className="block w-2/3 rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-2"
                                    value={isApiKeyExists ? (isVisible ? apiKey : '********************************************************************************') : ''}
                                    />
                                    <button type="button" onClick={handleVisibleChange} className="rounded-md mx-2 text-gray-600 hover:text-gray-500">
                                        { isVisible ? <FaRegEye className="h-5 w-5" /> : <FaRegEye className="h-5 w-5" /> }
                                    </button>
                                    <button type="button" onClick={() => handleCopyToClipboard(apiKey)} className="rounded-md mx-2 text-gray-600 hover:text-gray-500">
                                        { !isCopied ? <ClipboardIcon className="h-5 w-5" /> : <CheckIcon  className="w-5 h-5" /> }
                                    </button>
                                </div>
                                <div className="">
                                    <button className="w-full sm:w-1/4 rounded-md bg-gray-800 py-2 mb-10 text-sm font-semibold text-white shadow-sm hover:bg-gray-900" onClick={generateOrUpateApiKey}> { isApiKeyExists ? 'update api key' : 'generate api key' } </button>
                                </div>
                                {/* <div className="">
                                    <button className="w-full sm:w-1/3 rounded-md bg-gray-800 py-2 mb-10 text-md font-semibold text-white shadow-sm hover:bg-gray-900" onClick={() => {}}> save </button>
                                </div> */}
                                { apiKeyStatusMessage && (
                                    <span className="text-sm text-green-600"> { apiKeyStatusMessage } </span>
                                )}
                            </div>
                        </>
                    )}
                    </SidebarLayout>
                </div>
            {/* )} */}
        </>
    )
}

export default ECommercePage;