import React, { useEffect, useState, useRef } from 'react';
import { nip04 } from 'nostr-tools';
import QRCode from 'qrcode.react';

import PayWithFlashButton from '../../payWithFlash/payWithFlashButton';
import LnInvoiceHashDisplayAndCopy from '../../paymentComponents/LnInvoiceHashDisplayAndCopy';




// Function used to create the Zap Invoice
async function createZapInvoiceWithNWC(nostrWalletConnectUrl, amount, memo) {
    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice_with_nwc', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nostrWalletConnectUrl: nostrWalletConnectUrl,
            amount: amount,
            memo: memo
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to create zap invoice');
    }
    // const data = "lnbc10u1pj6j4gzpp5tzam0ynwcfv3prxmlj59lg40ssw3qfdyceeq0kzsmdg7vrg9kejqdpv2pshjmt9de6zqen0wgsy67fq2pshjmt9de6zqnrfde4scqzzsxqyz5vqsp5nmgtcgn3lnw2amw7rugnmtrdu9mfcw7kend7n3z5mk8rnh2symps9qyyssqvg8604jgpkc8eyknyekr5l8gyu8glclq09xdk8fcjep2jlj5cgrr53uc5lsmlsepe50hgvtgpdjd8v7w3xwdc7qwv02yq2rph9v4ulqqt69u8p"
    return data;
}

const fetchUserData = async(user_public_key) => {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/get_user_by_public_key?user_public_key=${encodeURIComponent(user_public_key)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
}

// Function to get the referral data from the db using user public key
async function fetchReferralDataWithPublicKey(userPublicKey) {
    const formData = new FormData ();
    formData.append('userPublicKey', userPublicKey);
    try {

        const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
        method: 'POST',
        body: formData,
        });

        if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrowing the error for handling it further up in the call stack
    }
}

// Function to get the nwc url from the db using the nwc_id
async function fetchWalletUrlById(wallet_id) {
    try {
        const url = new URL('https://api.paywithflash.com/api/get_wallet_from_id');
        url.searchParams.append('wallet_id', wallet_id);

        const response = await fetch(url);

        if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrowing the error for handling it further up in the call stack
    }
}

// Function used to send the Zap Invoice to the user and request payment via webln
async function requestWebLnPayment(invoice) {
    if (typeof window.webln === "undefined") {
        throw new Error("No WebLN available. Get a bitcoin lightning wallet. We recommend www.getalby.com");
    }

    try {
        await window.webln.enable();
        const result = await window.webln.sendPayment(invoice);
        return result;
    } catch (error) {
        console.error("Failed to pay invoice:", error);
    }
}



// Function to check if the invoice was paid
async function checkInvoicePayment(nostrWalletConnectUrl, invoice) {
    const startTime = Date.now();
    let isPaid = false;

    while (Date.now() - startTime < 300000) { // Loop for max 5 minutes
        try {
            const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/check_invoice_payment_with_nwc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nostrWalletConnectUrl: nostrWalletConnectUrl,
                    invoice: invoice
                }),
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to check payment');
            }

            if (data.is_paid) {
                isPaid = true;
                break;
            }

            // If not paid, wait 1 second before trying again
            await new Promise(resolve => setTimeout(resolve, 1000));

        } catch (error) {
            console.error("Error checking invoice payment:", error);
            // We wait 1 second before trying again
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    return isPaid;
}

// Handles payment via extension
const handlePaymentClick = async (invoice) => {
    if (!invoice) {
        console.error('No invoice to pay');
        return;
    }
    try {
        const result = await requestWebLnPayment(invoice);
        if (result) {
            // Payment status is already being checked in the background
            // Optionally do something with result
        }
    } catch (error) {
        console.error('Error in handling payment:', error);
    }
};

const ProductPayment = ({ product, quantity, handlePaymentSuccess }) => {

    const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
    const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;

    const encryptedParallelEConomyNwcUrl = process.env.REACT_APP_PARALLEL_ECONOMY_NWC_URL;
    const parallelEConomyPublicKey = process.env.REACT_APP_PARALLEL_ECONOMY_NOSTR_PUBLIC_KEY;

    const [currentPrice, setCurrentPrice] = useState();
    const [walletData, setWalletData] = useState({});
    const [nwcUrl, setNwcUrl] = useState('');
    const [priceInDollars, setPriceInDollars] = useState('');
    const [priceInSats, setPriceInSats] = useState('');
    const [bitcoinPrice, setBitcoinPrice] = useState('');
    const [isPaid, setIsPaid] = useState(false);
    const [invoice, setInvoice] = useState('');

    const [loading, setLoading] = useState(false);

    const [userFee, setUserFee] = useState();

    // Referral Data
    const [isReferralExists, setIsReferralExists] = useState();
    const [referralData, setReferralData] = useState();
    const [referralWalletData, setReferralWalletData] = useState();

    const [payerUserPublicKey, setPayerUserPublicKey] = useState('') || "";
    const [payerSelectedWallet, setPayerSelectedWallet] = useState({});


    useEffect (() => {
        if (product.user_public_key) {

            const getUserData = async() => {
                const userData = await fetchUserData(product.user_public_key);
                setUserFee(userData.fee);
            }

            const fetchReferralData = async () => {
                const referralData = await fetchReferralDataWithPublicKey(product.user_public_key);
                setIsReferralExists (referralData.isReferralExists);
                setReferralData(referralData.referralData);
        
                if (referralData.isReferralExists) {
                    // speical processing for parallel economy
                    if (!referralData.referralData.wallet_id) {
                        const referralWalletData = {};
                        referralWalletData.nwc_url = encryptedParallelEConomyNwcUrl;
                        setReferralWalletData(referralWalletData);
                    }
                    else {
                        const referralWalletData = await fetchWalletUrlById(referralData.referralData.wallet_id);
                        setReferralWalletData (referralWalletData);
                    }
                }
            }

            getUserData();
            fetchReferralData();
        }
    }, [product])

    // useEffect to create an invoice
    useEffect(() => {
        const nostrWalletConnectUrl = product.wallet_nwc;
        const memo = `Purchase: ${product.Name}, ${product.Description.substring(0, 50)}...`;

        setTimeout (async () => {
            const bitcoinPrice = await getBitcoinPrice ();
            setBitcoinPrice (bitcoinPrice);
        }, 100)

        let priceInSats = product.PriceInSatoshis;
        let priceInDollars = product.PriceInDollars;

        if (product.Discount) {
            priceInSats = parseInt (priceInSats * (1 - Number(product.Discount) / 100));
            priceInDollars = parseFloat (priceInDollars * (1 - Number(product.Discount) / 100));
        }

        setPriceInSats (priceInSats * quantity);
        setPriceInDollars (priceInDollars * quantity);

        setLoading(true);
        createZapInvoiceWithNWC(nostrWalletConnectUrl, priceInSats * quantity, memo)
            .then(data => {
                setInvoice(data.invoice);
                setLoading(false);
            })
            .catch(error => {
                console.error('Failed to create invoice:', error);
                setLoading(false);
                // Handle error (e.g., show an error message to the user)
            });
    }, [quantity]);

    // useEffect to check the invoice payment status
    useEffect(() => {
        const nostrWalletConnectUrl = product.wallet_nwc;
        if (invoice) {
            checkPaymentStatus(nostrWalletConnectUrl, invoice).then((paid) => {
                if (paid) {
                    handlePaymentSuccess();
                }
            });
        }
    }, [invoice]); // React to changes in `invoice`

    // Function to get the price of Bitcoin
    async function getBitcoinPrice() {
        try {
            const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
            method: 'POST', // if the API requires POST method
            headers: {
                'Content-Type': 'application/json',
            },
            // If the API requires a body payload, uncomment and edit the following line
            // body: JSON.stringify({ key: 'value' }), 
            });
        
            if (!response.ok) {
            // If the response is not OK, throw an error with the response status
            throw new Error(`API call failed with status: ${response.status}`);
            }
        
            const data = await response.json();
        
            // Assuming the API returns an object with a property that contains the Bitcoin price
            return data
        } catch (error) {
            console.error('API call failed:', error);
            throw error; // Rethrow the error to be handled by the caller
        }
    }

    // The function used to for the user to pay the fee owed to referred him
    async function payFeeToReferral(encryptedReferralUserNostrWalletConnectUrl, userNostrWalletConnectUrl, amount, memo) {

        const transactionDate = new Date().toISOString();

        // Call your server-side API to decrypt the config with nostr nip04
        const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_referral_with_nwc', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                referralUserPublicKey: referralData.user_public_key,
                encryptedReferralUserNostrWalletConnectUrl: encryptedReferralUserNostrWalletConnectUrl,
                userPublicKey: product.user_public_key,
                userNostrWalletConnectUrl: userNostrWalletConnectUrl,
                amount: amount,
                memo: memo,
                amount_in_sats: priceInSats,
                value_in_usd: priceInDollars,
                usd_btc_exchange_rate: bitcoinPrice,
                flash_id: -1,
                transaction_date: transactionDate,
            }),
        });
        const data = await response.json();

        if (!response.ok) {
        throw new Error(data.message || 'Failed to decrypt config');
        }

        return data;
    }

    // The function used to for the user to pay the fee owed to Flash
    async function payFeeToFlash(userNostrWalletConnectUrl, amount, memo) {
        const transactionDate = new Date().toISOString();
        const body = JSON.stringify({ 
            userPublicKey: product.user_public_key,
            userNostrWalletConnectUrl: userNostrWalletConnectUrl,
            amount: amount,
            memo: memo,
            amount_in_sats: priceInSats,
            value_in_usd: priceInDollars,
            usd_btc_exchange_rate: bitcoinPrice,
            flash_id: -1,
            transaction_date: transactionDate,
            user_public_key: product.user_public_key
          });
        
        // Call your server-side API to decrypt the config with nostr nip04
        const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash_with_nwc', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            userPublicKey: product.user_public_key,
            userNostrWalletConnectUrl: userNostrWalletConnectUrl,
            amount: amount,
            memo: memo,
            amount_in_sats: priceInSats,
            value_in_usd: priceInDollars,
            usd_btc_exchange_rate: bitcoinPrice,
            flash_id: -1,
            transaction_date: transactionDate,
            user_public_key: product.user_public_key
          }),
        });
        const data = await response.json();
  
        if (!response.ok) {
          throw new Error(data.message || 'Failed to decrypt config');
        }
  
        return data;
    }

    // Checks the payment status of the invoice
    const checkPaymentStatus = async (nostrWalletConnectUrl, invoice) => {
        const paymentStatus = await checkInvoicePayment(nostrWalletConnectUrl, invoice);
        if (paymentStatus) {
            // Payment success
            handlePaymentSuccess(); 
            // Call the callback function passed as a prop
            // Calculate the fee and price minus fee
            const feePercentage = userFee; // 1%

            // Assuming priceInSats is already defined and is the total price in Satoshis
            let satsFee = priceInSats * (feePercentage / 100); // Calculate the fee
            satsFee = Math.round(satsFee); // Rounding to the nearest whole number

            let satsFeeToFlash, satsFeeToReferral;
            if (isReferralExists) {
                const payPercent = referralData.pay_percent;
                satsFeeToFlash = satsFee * (100 - payPercent) / 100;
                satsFeeToReferral = satsFee * payPercent / 100;
                satsFeeToFlash = satsFee * 70 / 100;
                satsFeeToReferral = satsFee * 30 / 100;
                await payFeeToReferral(referralWalletData.nwc_url, nostrWalletConnectUrl, satsFeeToReferral, `Referral Fee for Product: ${product.id}`);
            }
            else satsFeeToFlash = satsFee;

            // Pay fee to flash
            const response = await payFeeToFlash(nostrWalletConnectUrl, satsFeeToFlash, `Fee for Flash product: ${product.Name}`);
            setIsPaid(true);
        }
    };





    return (
        <>
            {loading && (
                <div className="loading-container absolute inset-0 flex items-center justify-center" style = {{ zIndex : '100'}}>
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12 mb-4"></div>
                </div>
            )}
            <div className="flex justify-center items-center">
                <div className="w-full max-w-md px-8 pt-8 space-y-4 rounded-2xl bg-white">
                    {!isPaid ? (
                        <>
                            <div className = "text-center text-3xl font-bold font-semibold mb-2 text-slate-900 mt-3"> Pay with Lightning </div>
                            {/* QR Code Centered */}
                            <div className="flex justify-center">
                                <QRCode
                                    value={invoice}
                                    size={180}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    renderAs={"svg"}
                                    imageSettings={{
                                        src: "/favico.png",
                                        excavate: true,
                                        height: 40,
                                        width: 40,
                                    }}
                                />
                            </div>

                            {/* Payment Information */}
                            <div className="text-center">
                                <div className="flex flex-col items-center justify-center mb-2">
                                    { product.Discount && (
                                        <>
                                            <span className="priceInSats text-3xl font-bold text-slate-900">{parseInt(product.PriceInSatoshis * (1 - Number(product.Discount) / 100) * quantity).toLocaleString ()} sats</span>
                                            <span className="priceInDollars font-medium text-lg text-slate-600">${parseFloat(product.PriceInDollars * (1 - Number(product.Discount) / 100) * quantity).toLocaleString()}</span>{' '}
                                        </>
                                    )}
                                    { !product.Discount && (
                                        <>
                                        <span className="priceInSats text-3xl font-bold text-slate-900">{parseInt(product.PriceInSatoshis * quantity).toLocaleString ()} sats</span>
                                        <span className="priceInDollars font-medium text-lg text-slate-600">${parseFloat(product.PriceInDollars * quantity).toLocaleString()}</span>{' '}
                                    </>
                                    )}
                                </div>
                                <LnInvoiceHashDisplayAndCopy value={invoice} />
                            </div>



                        </>
                    ) : (
                        <div className="text-center">
                            <p className="font-semibold text-green-500">Payment successful! Thank you.</p>
                        </div>
                    )}


                    <div className="flex justify-center space-x-4 mt-4">
                        {/* <PayWithFlashButton
                            className="text-lg bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onPaymentSuccess={handlePayWithFlashSuccess}
                        /> */}
                        <button
                            className="text-lg bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={() => handlePaymentClick(invoice)} // Corrected this line
                        >
                            Pay with Extension
                        </button>
                    </div>
                </div>
            </div>
        </>
    );


};

export default ProductPayment;