import React, { useEffect, useState } from 'react';
import { nip04, getPublicKey } from 'nostr-tools';
import { webln } from "@getalby/sdk";
import Lottie from 'react-lottie';

import confettiAnimation from '../assets/confetti_animation.json';

// import ExternalAppLogin from './Login';
import ExternalAppLogin from '../components/ExternalAppLogin/Login';
import SuccessScreen from '../components/ExternalAppLogin/SuccessScreen';
import SuccessScreenHasSubscription from '../components/ExternalAppLogin/SuccessScreenHasSubscription';
import SelectSubscription from '../components/ExternalAppLogin/SelectSubscription';
import SelectSubscriptionForUnpaidSubscription from '../components/ExternalAppLogin/SelectSubscriptionForUnpaidSubscription';
import SelectWallet from '../components/ExternalAppLogin/SelectWallet';
import Summary from '../components/ExternalAppLogin/Summary';



// Function that calls the API to check if the user has a membership for the flash_id (look in table user_memberships where user_public_key = userPublicKey and flash_id = flashId)
async function getUserMembershipsForFlashId(externalUuid, userPublicKey, flashId) {
  try {
      const response = await fetch(`https://api.paywithflash.com/api/get_user_memberships_for_flash_id?external_uuid=${encodeURIComponent(externalUuid)}&user_public_key=${encodeURIComponent(userPublicKey)}&flash_id=${encodeURIComponent(flashId)}`);

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      // You might want to return the data here if you need to use it where the function is called
      return data;
  } catch (error) {
      // Log the error to the console
      console.error('Error fetching user memberships:', error);
      // Optionally, return or handle the error in another way
      throw error;
  }
}

// Function that checks the status of membership payment
async function checkUserMembershipsForFlashId(membership) {
  
}
    // If there is a membership, get all membership details
      // If the membership has a status of "paid", return true: the user_public_key is validated
      // If the membership has a status of "not paid", return false: the user is not validated. We should keep the membership details to select them as defaults in the pay screen
    // If there isn't a membership, display the component with all membership plans, all user wallets, and the button paid



// Function that calls API to get flash details and return the flash name
const getFlashById = async (flashId) => {
  const API_URL = 'https://api.paywithflash.com/api/get_flash_from_id';

  try {
    const response = await fetch(`${API_URL}?flash_id=${encodeURIComponent(flashId)}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    
    const data = await response.json();

    if (data.error) {
      console.error('Error fetching flash data:', data.error);
      return null; // or handle the error as needed
    }

    return data; // Return the flash
  } catch (error) {
    console.error('Error in getFlashNameById:', error);
    return null; // or handle the error as needed
  }
};


// Function that calls API to get membershipsfor the flash id
async function getMembershipsByFlashId(flashId) {
  try {
      const response = await fetch(`https://api.paywithflash.com/api/get_memberships_by_flash_id?flash_id=${encodeURIComponent(flashId)}`);

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      // You might want to return the data here if you need to use it where the function is called
      return data;
  } catch (error) {
      // Log the error to the console
      console.error('Error fetching memberships:', error);
      // Optionally, return or handle the error in another way
      throw error;
  }
}



// Function that calls API to get memberships by id
async function getMembershipsById(id) {
  try {
      const response = await fetch(`https://api.paywithflash.com/api/get_membership_by_id?id=${encodeURIComponent(id)}`);

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      // You might want to return the data here if you need to use it where the function is called
      return data;
  } catch (error) {
      // Log the error to the console
      console.error('Error fetching memberships:', error);
      // Optionally, return or handle the error in another way
      throw error;
  }
}


// Helper function to fetch wallet balance
async function getWalletBalance(nwcUrl) {
  const nwc = new webln.NostrWebLNProvider({
      nostrWalletConnectUrl: nwcUrl,
  });
  await nwc.enable();
  const balanceResponse = await nwc.getBalance();
  nwc.close();
  return balanceResponse;
}


const getWalletLogoUrlFromNWCString = async (nwcString) => {
  const walletsAvailable = [
      {
          name: "alby",
          logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/AlbyWalletLogo.png",
      },
      {
          name: "mutiny",
          logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/MutinyWalletLogo.jpeg",
      },
  ];

  const lowerCaseNWCString = nwcString.toLowerCase();

  for (let i = 0; i < walletsAvailable.length; i++) {
      if (lowerCaseNWCString.includes(walletsAvailable[i].name)) {
          return walletsAvailable[i].logoURL;
      }
  }

  // Return "Custom" or a default image if no match is found
  return "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png"; // Replace with your default wallet logo URL
};



// Function that gets all user wallets
async function getUserWallets(userPrivateKey, publicKey) {
  const userPublicKey = getPublicKey(userPrivateKey);
  try {
      const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userPublicKey)}`);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const walletPromises = data.map(async item => {
          let decrypted_nwc_url;
          if (item.nwc_url !== "") {
              decrypted_nwc_url = await nip04.decrypt(userPrivateKey, publicKey, item.nwc_url);
          } else {
              decrypted_nwc_url = "";
          }

          // Fetch balance and logo URL concurrently
          const balancePromise = getWalletBalance(decrypted_nwc_url); // Abstracted balance fetching to a function
          const logoUrlPromise = getWalletLogoUrlFromNWCString(decrypted_nwc_url); // Fetch logo URL

          try {
              const [balanceResponse, walletLogoUrl] = await Promise.all([balancePromise, logoUrlPromise]);

              return {
                  id: item.id,
                  user_publicKey: item.user_public_key,
                  wallet_name: item.wallet_name,
                  user_wallet_name: item.user_wallet_name,
                  nwc_url: item.nwc_url,
                  balance: balanceResponse.balance, // Adding balance to the result
                  wallet_logo_url: walletLogoUrl // Adding wallet logo URL to the result
              };
          } catch (error) {
              console.error('Error processing wallet:', error);
              return {
                  ...item,
                  nwc_url: item.nwc_url,
                  balance: 'Error', 
                  wallet_logo_url: 'default-logo-url.jpg' // Replace with your default logo URL
              };
          }
      });

      return await Promise.all(walletPromises);
  } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      throw error;
  }
}


// Function that get wallet linked to flash
async function getWalletFromId(walletId) {
  try {
      const response = await fetch(`https://api.paywithflash.com/api/get_wallet_from_id?wallet_id=${encodeURIComponent(walletId)}`);

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
      }

      const walletDetails = await response.json();
      // You might want to return the wallet details here if you need to use them where the function is called
      return walletDetails;
  } catch (error) {
      // Log the error to the console
      console.error('Error fetching wallet details:', error);
      // Optionally, return or handle the error in another way
      throw error;
  }
}



// The function used to create the Zap Invoice
async function createZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) {
  try {
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          userPublicKey: userPublicKey,
          encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
          amount: amount,
          memo: memo
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      console.error("Response Error: ", await response.text());
      throw new Error(data.message || 'Failed to create invoice');
    }
    return data.invoice;

  } catch (error) {
    console.error("Error in createZapInvoice: ", error);
    // Rethrow the error for the caller to handle
    throw error;
  }
}



// Function used to pay Zap Invoices
async function payZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, invoice) {

  try {
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_zap_invoice', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              userPublicKey,
              encryptedUserNostrWalletConnectUrl,
              invoice,
          }),
      });

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result.is_paid; // This will be the JSON object { is_paid: true/false }
  } catch (error) {
      // Log the error to the console or handle it in another way
      console.error('Error making payment:', error);
      throw error;
  }
}


// Get the next payment date based on the membershi renewal interval
function getNextRenewalDateISOString(renewalInterval) {
  const currentDate = new Date();

  switch (renewalInterval) {
      case "Yearly":
          currentDate.setFullYear(currentDate.getFullYear() + 1);
          break;
      case "Monthly":
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
      case "Weekly":
          currentDate.setDate(currentDate.getDate() + 7);
          break;
      case "Lifetime":
          currentDate.setFullYear(currentDate.getFullYear() + 100);
          break;
      default:
          throw new Error("Invalid renewal interval");
  }

  return currentDate.toISOString();
}


// Function used to save the membership chosen by the user in table user_memberships
async function saveUserMembership(userPublicKey, flashId, externalUuid, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {

  try {
      const response = await fetch('https://api.paywithflash.com/api/save_user_membership', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              user_public_key: userPublicKey,
              flash_id: flashId,
              external_uuid: externalUuid,
              wallet_id: walletId,
              membership_id: membershipId,
              is_paid: isPaid,
              membership_start_date: membershipStartDate,
              next_payment_date: nextPaymentDate,
              status: status
          })
      });

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result; // Handle the JSON response as needed
  } catch (error) {
      // Log the error to the console or handle it in another way
      console.error('Error saving user membership:', error);
      throw error;
  }
}



// Function used to update the membership chosen by the user in table user_memberships
async function updateUserMembership(user_membership_id, userPublicKey, flashId, externalUuid, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {

  try {
      const response = await fetch('https://api.paywithflash.com/api/update_user_membership', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              user_membership_id: user_membership_id,
              user_public_key: userPublicKey,
              flash_id: flashId,
              external_uuid: externalUuid,
              wallet_id: walletId,
              membership_id: membershipId,
              is_paid: isPaid,
              membership_start_date: membershipStartDate,
              next_payment_date: nextPaymentDate,
              status: status
          })
      });

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result; // Handle the JSON response as needed
  } catch (error) {
      // Log the error to the console or handle it in another way
      console.error('Error saving user membership:', error);
      throw error;
  }
}


// Function to save the transaction to table transactions of the db
async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
  const formData = new FormData();
  formData.append('from_public_key', from_public_key);
  formData.append('to_public_key', to_public_key);
  formData.append('transaction_date', transactionDate);
  formData.append('amount_in_sats', amountInSats);
  formData.append('value_in_usd', valueInUsd);
  formData.append('flash_id', flashId);
  formData.append('flash_name', flashName);
  formData.append('flash_type', flashType); // Assuming this is also a new requirement
  formData.append('to_wallet_id', toWalletId);
  formData.append('to_user_wallet_name', toUserWalletName);
  formData.append('from_wallet_id', fromWalletId);
  formData.append('from_user_wallet_name', fromUserWalletName);
  formData.append('lnurl', lnurl);

  try {
    const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save transaction to database');
    }

    const successData = await response.json();
    console.log('Transaction successfully saved to database:', successData);
    return successData;
  } catch (error) {
    console.error('Error saving transaction to database:', error);
    throw error;
  }
}

async function sendPayInfoViaWebhook (flashId, userPublicKey, plan, userName, userAbout, userPictureUrl, eventType, signUpDate, failedPaymentDate, transactionId, transactionDate) {

  const payload = {
    eventType: eventType,
    flashId: flashId,
    userPublicKey: userPublicKey,
    plan: plan,
    userName: userName,
    userAbout: userAbout,
    userPictureUrl: userPictureUrl,
    signUpDate: signUpDate,
    failedPaymentDate: failedPaymentDate,
    transactionId: transactionId,
    transactionDate: transactionDate
  };

  try {
    const response = await fetch('https://api.paywithflash.com/api/send_payinfo_via_webhook', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status : ${response.status}`);
    }
  } catch (err) {
    console.log("Error sending pay info to Geyser", err);
    throw err;
  }
}






async function getFlashPublicKey() {
  try {
    // Make a GET request to the API endpoint
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/getFlashPublicKey', {
      method: 'GET',
      headers: {
        // You can add headers if needed, such as authentication headers
      },
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // Parse the JSON response to get the public key
      const data = await response.json();
      return data.publicKey;
    } else {
      // Handle the error if the response status is not 200
      throw new Error(`API call failed with status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error in getFlashPublicKey:', error);
    throw error;
  }
}

// Function to get the price of Bitcoin
async function getBitcoinPrice() {
  try {
      const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
          method: 'POST', // if the API requires POST method
          headers: {
              'Content-Type': 'application/json',
          },
          // If the API requires a body payload, uncomment and edit the following line
          // body: JSON.stringify({ key: 'value' }), 
      });

      if (!response.ok) {
          // If the response is not OK, throw an error with the response status
          throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();

      // Assuming the API returns an object with a property that contains the Bitcoin price
      return data
  } catch (error) {
      console.error('API call failed:', error);
      throw error; // Rethrow the error to be handled by the caller
  }
}



async function payFeeToFlash(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo, priceInSats, priceInDollars, flashId, user_public_key) {
  try {
    // Get transaction date
    const transactionDate = new Date().toISOString();

    // Get the price of bitcoin
    const bitcoinPrice = await getBitcoinPrice(); // If getBitcoinPrice is async, otherwise remove await

    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        amount_in_sats: priceInSats,
        value_in_usd: priceInDollars,
        usd_btc_exchange_rate: bitcoinPrice,
        flash_id: flashId,
        transaction_date: transactionDate,
        user_public_key: user_public_key
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }
    console.log('Fee transaction successfully saved to database:', data);
    return data;
  } catch (error) {
    console.error('Error in payFeeToFlash:', error);
    throw error; // Re-throw the error if you want the calling function to handle it
  }
}

const ExternalAppLoginPage = () => {



  // States for data received upon page load
  const [publicKey, setPublicKey] = useState(null);
  const [appName, setAppName] = useState('');
  const [flashDetails, setFlashDetails] = useState('');
  const [flashUrl, setFlashUrl] = useState('') || "";
  const [flashWalletDetails, setFlashWalletDetails] = useState('');
  const [flashId, setFlashId] = useState('');
  const [sourceUrl, setSourceUrl] = useState('');
  

  // States for user data
  const [userData, setUserData] = useState('') || "";
  const [userEmail, setUserEmail] = useState('') || "";
  const [userPassword, setUserPassword] = useState('') || "";
  const [userPrivateKey, setUserPrivateKey] = useState('') || "";
  const [userPublicKey, setUserPublicKey] = useState('') || "";
  const [userName, setUserName] = useState('') || "";
  const [userPictureUrl, setUserPictureUrl] = useState('') || "";
  const [userAbout, setUserAbout] = useState('') || "";

  // States for subscription plans
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [userMembershipForFlashId, setUserMembershipForFlashId] = useState(null);


  // States for user wallet
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);

  // States for managing app states
  const [userLogin, setUserLogin] = useState(true);
  const [successScreen, setSuccessScreen] = useState (false);
  const [selectSuccessScreenHasSubscription, setSelectSuccessScreenHasSubscription] = useState (false);
  const [isRenewal, setIsRenewal] = useState(false);
  const [selectPlan, setSelectPlan] = useState(false);
  const [selectPlanForUnpaidSubscription, setSelectPlanForUnpaidSubscription] = useState(false);
  const [selectWallet, setSelectWallet] = useState(false);
  const [summary, setSummary] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [loading, setLoading] = useState(false);

  // State to control the confetti animation
  const [showConfettiAnimation, setShowConfettiAnimation] = useState(false);

  // Lottie options for the confetti animation
  const defaultOptions = {
    loop: false,
    autoplay: false, // Controlled via state
    animationData: confettiAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };


  // Function to send message to the appropriate window. Single function to send messages to the source, covering both iframe and redirect scenarios
  const sendMessageToSource = (data) => {
    let targetWindow;
    let targetOrigin = '*'; // Ideally, this should be the exact origin for security

    // Determine if there's a sourceUrl or use document.referrer
    const effectiveSourceUrl = sourceUrl || document.referrer;
    if (effectiveSourceUrl) {
      const urlObj = new URL(effectiveSourceUrl);
      targetOrigin = urlObj.origin; // Use the origin part of the URL for targetOrigin
    }

    // Determine the target window based on the context
    if (window.parent !== window) {
      // The page is within an iframe
      targetWindow = window.parent;
    } else if (window.opener) {
      // The page was opened via a redirect/window.open
      targetWindow = window.opener;
    }

    // Send the message if a target window is determined
    if (targetWindow) {
      targetWindow.postMessage(data, targetOrigin);
    }
  };



  useEffect(() => {
    // Extracts URL parameters, useful for both redirects and initial iframe load
    const queryParams = new URLSearchParams(window.location.search);
    const flashId = queryParams.get('flashId');
  
    setFlashId(flashId);
    // Uses document.referrer to set the source URL; useful for both iframe and redirects
    setSourceUrl(document.referrer);
  
  }, []); // Runs once on component mount
  
  
  
  



  
  // Hook to get the flash public key
  useEffect(() => {
    async function fetchPublicKey() {
      try {
        const retrievedPublicKey = await getFlashPublicKey();
        setPublicKey(retrievedPublicKey);
      } catch (error) {
        console.error('Error fetching flash public key:', error);
      }
    }

    fetchPublicKey();
  }, []);



  // Get the details from the app that directed the user here: flashId and origin from the URL
  useEffect(() => {

    if (flashId) {
      getFlashById(flashId)
        .then(flash => {
          if (flash) {
            setFlashDetails(flash)
          }
          if (flash.name) {
            setAppName(flash.name);
          }
          if (flash.url) {
            setFlashUrl(flash.url);
          }
        });
    }

    // Here, we may want to compare sourceUrl with the flashUrl for security
    // ...

  }, [flashId]);


  // Get the flash wallet details
  useEffect(() => {
    const fetchWalletDetails = async () => {
      if (flashDetails) {
        const flashWalletId = flashDetails.wallet_id;

        // Get the details of the wallet from the db and set to state variable
        try {
          const flashWalletDetails = await getWalletFromId(flashWalletId);
          setFlashWalletDetails(flashWalletDetails);
        } catch (error) {
          console.error('Error fetching wallet details:', error);
          // Handle the error as needed
        }
      }
    };

    fetchWalletDetails();
  }, [flashDetails]);


  const handleSelectPlan = async (plan) => {
    // Set the selected plan to the state
    setSelectedPlan(plan);

    // Hide the Selet Plan interface
    setSelectPlan(false);
    setSelectPlanForUnpaidSubscription(false);
    // Show the Select Wallet interface
    setSelectWallet(true);

  };



  const handleSelectWallet = async (wallet) => {
    // Set the selected plan to the state
    setSelectedWallet(wallet);

    // Hide the Selet Wallet interface
    setSelectWallet(false);
    // Show the Summary interface
    setSummary(true);

  };



  const handleBackFromSelectWallet = () => {
    // Assuming you want to go back to the plan selection
    setSelectWallet(false);
    setSelectPlan(true); // or setSelectPlanForUnpaidSubscription(true); based on your logic
  };
  
  const handleBackFromSummary = () => {
    // Assuming you want to go back to wallet selection
    setSummary(false);
    setSelectWallet(true);
  };
  



  const handleConfirm = async () => {
    // Hide the Summary interface
    setSummary(false);
    // Set the error to false
    setSummaryError(false);

    setLoading(true);

    try {

      // Create invoice with flash wallet nwc
      const invoice = await createZapInvoice(
        flashWalletDetails.publicKey,
        flashWalletDetails.nwc_url,
        selectedPlan.priceInSats,
        `Payment for ${selectedPlan.name} plan with ${appName}`
      );
    
      // Pay invoice with user wallet nwc
      const is_paid = await payZapInvoice(userPublicKey, selectedWallet.nwc_url, invoice)

      // If successful, save data to db
      if (is_paid) {

        // Trigger the confetti animation
        setShowConfettiAnimation(true);
        // Reset the animation state after its duration (e.g., 3000ms for a 3-second animation)
        setTimeout(() => {
          setShowConfettiAnimation(false);
        }, 3000);

        // Get current date and next payment date
        const currentDate = new Date().toISOString();
        const nextRenewalDate = getNextRenewalDateISOString(selectedPlan.renewal_intervals);

        

        // If it is a renewal
        if (isRenewal){
         // Update the existing user_memberships row
          await updateUserMembership(userMembershipForFlashId.id, userPublicKey, flashId, userMembershipForFlashId.external_uuid, selectedWallet.id, selectedPlan.id, "true", currentDate, nextRenewalDate, "active")
        } else {
          // Add line to table user_memberships
          await saveUserMembership(userPublicKey, flashId, userMembershipForFlashId.external_uuid, selectedWallet.id, selectedPlan.id, "true", currentDate, nextRenewalDate, "active")
        }

        // Also add the transaction to table transactions
        const transaction = await saveTransactionToDB(userPublicKey, flashDetails.user_public_key, currentDate, selectedPlan.priceInSats, selectedPlan.priceInDollars, flashId, flashDetails.name, "Subscription Plan", flashWalletDetails.id, flashWalletDetails.user_wallet_name, selectedWallet.id, selectedWallet.user_wallet_name, invoice)

        // Send the pay info to geyser
        const eventType = "user_signed_up";
        await sendPayInfoViaWebhook (flashId, userPublicKey, selectedPlan, userName, userAbout, userPictureUrl, eventType, currentDate, '', transaction.transaction_id, currentDate);

        // Calculate the fee and price minus fee
        const feePercentage = 1; // 1%

        // Assuming priceInSats is already defined and is the total price in Satoshis
        let satsFee = selectedPlan.priceInSats * (feePercentage / 100); // Calculate the fee
        satsFee = Math.round(satsFee); // Rounding to the nearest whole number

        // Pay fee to flash
        await payFeeToFlash(flashDetails.user_public_key, flashWalletDetails.nwc_url, satsFee, `Fee for flash id:  ${flashId}`, selectedPlan.priceInSats, selectedPlan.priceInDollars, flashId, flashDetails.user_public_key);

        // Construct the postData to send back to the app
        const postData = {
          user_has_paid: "true",
          user_membership_name: selectedPlan.name,
          user_public_key: userPublicKey,
          user_name: userName,
          user_about: userAbout,
          user_profile_picture_url: userPictureUrl
        };
        // Send the data
        sendMessageToSource(postData);

        setSummary (false);
        setSuccessScreen (true);
        setLoading (false);


      } else {
        // Else, show summary page again with an error
        setLoading(false);

        // Show the Summary interface
        setSummary(true);
        setSummaryError(true);
      }

    } catch (error) {
      // Else, show summary page again with an error
      setLoading(false);

      // Show the Summary interface
      setSummary(true);
      setSummaryError(true);

      console.error('Error creating membership:', error);
    }

  };

  const handleLoginSuccess = async (loginData) => {
    // Do something with loginData
    setUserData(loginData);
  
    // Get all login values
    const userEmail = loginData.email;
    const userPassword = loginData.password;
    const userPrivateKey = loginData.userPrivateKey;
    const userPublicKey = loginData.userPublicKey;
    const userName = loginData.userName;
    const userPictureUrl = loginData.userPictureUrl;
    const userAbout = loginData.userAbout;
  
    // Set all state variables
    setUserEmail(userEmail);
    setUserPassword(userPassword);
    setUserPrivateKey(userPrivateKey);
    setUserPublicKey(userPublicKey);
    setUserName(userName);
    setUserPictureUrl(userPictureUrl);
    setUserAbout(userAbout);

    // Get the user Wallets and set to state variable
    const userWallets = await getUserWallets(userPrivateKey, publicKey)
    setWallets(userWallets);
  
    
  
    // Query table to see if the user has an active membership for this flash_id. Use the user_public_key
    const UserMembershipsForFlashId = await getUserMembershipsForFlashId('', userPublicKey, flashId);

    // Check if the user_memberships array inside UserMembershipsForFlashId is empty or the status of the membership is not paid
    if (UserMembershipsForFlashId.user_memberships && UserMembershipsForFlashId.user_memberships.length === 0) {
      // Fetch plans and update state
      const membershipsData = await getMembershipsByFlashId(flashId);
      if (membershipsData && membershipsData.memberships) {
        setPlans(membershipsData.memberships);
      } else {
        setPlans([]); // Reset to empty array if no data found
      }
  
      // Set the login status to false
      setUserLogin(false);
      setSelectPlan(true);
  
      // Additional logic for handling memberships can be added here
    } else if (UserMembershipsForFlashId.user_memberships[0].is_paid === "false") {
      
      // Save the existing user membership to state var
      setUserMembershipForFlashId(UserMembershipsForFlashId.user_memberships[0])
  
      console.log("User Membership for Flash ID is not paid");
  
      // Fetch plans and update state
      const membershipsData = await getMembershipsByFlashId(flashId);
      if (membershipsData && membershipsData.memberships) {
        setPlans(membershipsData.memberships);
      } else {
        setPlans([]); // Reset to empty array if no data found
      }
  
      // Set the login status to false
      setUserLogin(false);
  
      // Set isRenewal variable to true
      setIsRenewal(true)
      // Set selectPlanForUnpaidSubscription variable to true
      setSelectPlanForUnpaidSubscription(true);
  
    } else {

        setUserLogin (false);
        setSelectSuccessScreenHasSubscription (true);

        const membershipInfo = await getMembershipsById(UserMembershipsForFlashId.user_memberships[0].membership_id)

        // Construct the postData to send back to the app
        const postData = {
          user_has_paid: "true",
          user_membership_name: membershipInfo.membership.name,
          user_public_key: userPublicKey,
          user_name: userName,
          user_about: userAbout,
          user_profile_picture_url: userPictureUrl
        };
        // Send the data
        sendMessageToSource(postData);
    }

  }


  return (
    <div className="fixed inset-0 flex items-center justify-center lg:bg-purple-100 lg:bg-opacity-50">
  
      {/* Modal Content */}
      <div className="w-full lg:w-2/5 mx-auto relative z-10 bg-white overflow-hidden content-inside shadow-none lg:shadow-lg m-4 lg:m-20 rounded-none lg:rounded-lg">
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        )}
        {userLogin && (
          <ExternalAppLogin appName={appName} imageUrl={flashDetails.flash_image_url} onLoginSuccess={handleLoginSuccess} />
        )}
        {selectSuccessScreenHasSubscription && (
          <SuccessScreenHasSubscription appName={appName} imageUrl={flashDetails.flash_image_url} />
        )}
        {selectPlan && (
          <SelectSubscription appName={appName} plans={plans} imageUrl={flashDetails.flash_image_url} userData={userData} onSelectPlan={handleSelectPlan} />
        )}
        {selectPlanForUnpaidSubscription && (
          <SelectSubscriptionForUnpaidSubscription appName={appName} userMembershipForFlashId={userMembershipForFlashId} imageUrl={flashDetails.flash_image_url} plans={plans} userData={userData} onSelectPlan={handleSelectPlan} />
        )}
  
        {/* If the user already selected a plan */}
        {selectWallet && (
          <SelectWallet appName={appName} publicKey={publicKey} imageUrl={flashDetails.flash_image_url} wallets={wallets} userData={userData} setWallets={setWallets} userPrivateKey={userPrivateKey} getUserWallets={getUserWallets} onSelectWallet={handleSelectWallet} onBack={handleBackFromSelectWallet} />
        )}
  
        {/* Show the summary interface */}
        {summary && (
          <Summary appName={appName} wallet={selectedWallet} plan={selectedPlan} imageUrl={flashDetails.flash_image_url} userData={userData} summaryError={summaryError} onConfirm={handleConfirm} onBack={handleBackFromSummary} />
        )}
        {successScreen &&
          <SuccessScreen appName={appName} imageUrl={flashDetails.flash_image_url} />
        }

        {/* Confetti Animation Overlay */}
        {showConfettiAnimation && (
          <div className="fixed inset-0 z-60 flex items-center justify-center">
            <Lottie options={defaultOptions} height={800} width={800} isStopped={!showConfettiAnimation} />
          </div>
        )}
      </div>
  
      
    </div>
  );
    
}

export default ExternalAppLoginPage;
