import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import { generatePrivateKey, getPublicKey, nip04 } from 'nostr-tools';

const VerifySuccessPageForParallelEconomy = () => {

  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
  const parallelEconomyPublicKey = process.env.REACT_APP_PARALLEL_ECONOMY_NOSTR_PUBLIC_KEY;

  const navigate = useNavigate ();
  const { token } = useParams ();

  const [isVerifySuccess, setIsVerifySuccess] = useState ();
  const [email, setEmail] = useState();
  const [userPublicKey, setUserPublicKey] = useState();
  const [OTP, setOTP] = useState();
  const [status, setStatus] = useState();

  const [isUpdatedUserDataSuccess, setIsUpdatedUserDataSuccess] = useState();

  useEffect (() => {
    if (token) {
      const verifyLink = async () => {
          try {
            const response = await fetch (`https://api.paywithflash.com/api/verify_token/${token}`);
      
            if (!response.ok) {
              console.error ('An Error in verifying the link');
              throw new Error (`An Error in verifying the link: ${response.status}`);
            }
            const data = await response.json();
            if (!data.success) {
              setStatus(data.error);
            }
            return data;
          } catch (error) {
            console.error (`An Error in verifying the link: ${error}`);
            throw new Error (`An Error in verifying the link: ${error}`);
          }
      }
  
      verifyLink ()
        .then ((data) => {
          setIsVerifySuccess (data.success);
          setEmail(data.data.email);
          setOTP(data.data.OTP);
        })
        .catch (error => {
          console.error (error);
        })
    }

  }, [token])

  useEffect (() => {
    if (isVerifySuccess) {
        const updateUserData = async() => {
          
          try {

            const formData1 = new FormData();
            formData1.append('user_public_key', parallelEconomyPublicKey);
            formData1.append('from_where', 'parallel-economy');

            // creating the referral part
            const response1 = await fetch('https://api.paywithflash.com/api/create_referral', {
              method: 'POST',
              body: formData1,
            });

            if (!response1.ok) {
              throw new Error('Network is not ok!');
            }

            const data1 = await response1.json();

            if (!data1.success) {
              throw new Error(data1.error);
            }

            // singup for user
            const newPrivateKey = await generatePrivateKey();
            const publicKey = await getPublicKey(newPrivateKey);
            
            setUserPublicKey(publicKey);
            const encryptedUserPrivateKey = await nip04.encrypt(newPrivateKey, flashPublicKey, newPrivateKey);
  
            const formData2 = new FormData();
            formData2.append('email', email);
            formData2.append('password', OTP);
            formData2.append('privateKey', encryptedUserPrivateKey);
            formData2.append('publicKey', publicKey);
            formData2.append('referralPublicKey', parallelEconomyPublicKey);
            formData2.append('verificationLinkRequired', false);
            formData2.append('verificationStatus', true);
  
            const response2 = await fetch('https://api.paywithflash.com/api/signup', {
              method: 'POST',
              body: formData2,
            });

            if (!response2.ok) {
              throw new Error('Network is not ok!');
            }
  
            const data2 = await response2.json();

            // if (!data2.success) {
            //   throw new Error(data2.error);
            // }

            setIsUpdatedUserDataSuccess(true);

          } catch(error) { 
            console.error(error);
            throw new Error(error);
          }
        }

        updateUserData();
    }
  }, [isVerifySuccess]);

  useEffect(() => {
    if (isUpdatedUserDataSuccess) {

      const sendNotification = async () => {
        try {
          
          const formData = new FormData();
          formData.append('email', email);
          formData.append('user_public_key', userPublicKey);
          formData.append('OTP', OTP);
  
          const response = await fetch('https://api.paywithflash.com/api/send_notification', {
            method: 'POST',
            body: formData,
          });
  
          const data = await response.json();
  
        } catch (error) {
          console.error(error);
          throw new Error(error);
        }
      }
  
      sendNotification();
    }
  }, [isUpdatedUserDataSuccess]);

  if (status) return (<div>{status}</div>);

  return (
    <>
      <div className="flex h-5/6 h-auto md:h-screen" style={{backgroundImage: "url('../nimages/bg-flash.png')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <div className="flex flex-col justify-center pt-4 md:pt-0 px-4 md:px-36 text-gray-900 w-full align-middle items-center">
          <div className="w-full lg:w-2/3 xl:w-1/3 border-slate-200 rounded-md p-7 pb-4 shadow-lg bg-white">
            <div className="flex grid grid-cols-3 gap-3 items-center">
              <img className="w-40 mb-3 -ml-1 -mt-0.5" src="/nimages/logo-org.png" alt="Flash Logo" />
              <div></div>
              <img className="w-40 mb-3 -mr-1 -mt-0.5" src="https://www.paralleleconomy.com/wp-content/uploads/2022/01/logo-white-shadow.png" alt="Parallel Economy Logo" />
            </div>
            <div className="items-center mb-0">
              <div className="mt-4">
                <h3 className="text-xl font-semibold text-gray-800">
                  Welcome! 🎉 Thanks for Signing Up!
                </h3>
                <h4 className="mt-3 text-gray-700"> 
                  You can now log in to <a href="https://app.paywithflash.com" className="text-blue-600 hover:underline">Flash</a> with a One Time Password:
                </h4>
                <div className="mt-4 text-gray-700">
                  <p><span className="font-semibold">Email:</span> {email}</p>
                  <p><span className="font-semibold">One Time Password:</span> {OTP}</p>
                </div>
                {/* <div className="mt-8 text-gray-600">
                  <p>Best regards,</p>
                  <p>Flash Support Team</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>  
      </div>
    </>
);

};

export default VerifySuccessPageForParallelEconomy;