import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Payment from '../components/ECommerce/Payment';
import ProductOrderFormDisplay from '../components/Products/Products/ProductOrderFormDisplay';
import SuccessScreen from './ProductGallerySuccessScreen';

const encodeData = (data) => {
    const jsonString = JSON.stringify(data);
    return encodeURIComponent(btoa(unescape(encodeURIComponent(jsonString))));
};

const decodeData = (data) => {
    return JSON.parse(decodeURIComponent(escape(atob(decodeURIComponent(data)))));
}

const convertPrices = async (currency, value) => {
    const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars
    const exchangeRate = await getExchangeRateFromDollars('PLN');

    if (currency === "usd") {
        const priceInSatoshis = Math.round(parseFloat(value) / bitcoinPriceUSD * 100000000);
        const priceInZloty = parseFloat(value) * exchangeRate;
        return { priceInDollars: parseFloat(value), priceInSatoshis, priceInZloty };
    } else if (currency === "satoshis") {
        const priceInDollars = (parseFloat(value) / 100000000 * bitcoinPriceUSD).toFixed(2);
        const priceInZloty = priceInDollars * exchangeRate;
        return { priceInDollars: parseFloat(priceInDollars), priceInSatoshis: value, priceInZloty };
    } else if (currency === 'zloty') {
        const priceInDollars = parseFloat(value) / exchangeRate;
        const priceInSatoshis = Math.round((priceInDollars / bitcoinPriceUSD) * 100000000);
        return { priceInDollars, priceInSatoshis, priceInZloty: parseFloat(value) };
    }
};

async function getBitcoinPrice() {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    return data;
}

async function getExchangeRateFromDollars(to_currency) {
    const formData = new FormData();
    formData.append('to_currency', to_currency);
    const response = await fetch('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
        method: 'POST',
        body: formData,
    });
    const data = await response.json();
    return data;
}

async function sendPurchaseConfirmationEmail(products, recipientEmail, totals, apiKey, service) {
    const apiUrl = 'https://api.paywithflash.com/ecommerce/api/send_purchase_confirmation_for_ecommerce';

    const requestData = {
        products: products,
        recipient_email: recipientEmail,
        totals: totals // Include totals in the email data
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json',
                'Service': service,
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to send purchase confirmation: ${errorData.error || response.status}`);
        }
        // console.log('Email sent successfully');
    } catch (error) {
        console.error('Error sending purchase confirmation:', error.message);
        throw error;
    }
}

async function createAndUploadZip(product) {
    try {
        const response = await axios.post('https://api.paywithflash.com/api/create_zip_endpoint', {
            product_id: product.ProductID,
            file_urls: product.DigitalProductURLs // Assuming you have URLs ready in the product object
        });
        // console.log('Zip created successfully');
        return response.data.url; // Assuming the API returns an object with a url property
    } catch (error) {
        console.error('Failed to create zip file:', error);
        throw new Error('Failed to create download link.');
    }
}

async function updateInventoryAfterPurchase(productId, quantity) {
    const url = 'https://api.paywithflash.com/api/update_inventory_after_purchase';
    const data = {
        product_id: productId,
        quantity: quantity
    };

    return axios.post(url, data)
        .then(response => {
            // console.log('Inventory updated successfully');
            return response.data;
        })
        .catch(error => {
            console.error('Error updating inventory:', error.response ? error.response.data : error.message);
            throw error;
        });
}

async function fetchReferralDataWithPublicKey(userPublicKey) {
    const formData = new FormData();
    formData.append('userPublicKey', userPublicKey);
    try {
        const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

async function fetchWalletUrlById(wallet_id) {
    try {
        const url = new URL('https://api.paywithflash.com/api/get_wallet_from_id');
        url.searchParams.append('wallet_id', wallet_id);

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

async function payFeeToReferral(encryptedReferralUserNostrWalletConnectUrl, userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo, referralData, totalInSats, totalPrice, bitcoinPrice) {
    const transactionDate = new Date().toISOString();
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_referral', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            referralUserPublicKey: referralData.user_public_key,
            encryptedReferralUserNostrWalletConnectUrl: encryptedReferralUserNostrWalletConnectUrl,
            userPublicKey: userPublicKey,
            encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
            amount: amount,
            memo: memo,
            amount_in_sats: totalInSats,
            value_in_usd: totalPrice,
            usd_btc_exchange_rate: bitcoinPrice,
            flash_id: -1,
            transaction_date: transactionDate,
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to pay referral');
    }

    return data;
}

async function payFeeToFlash(encryptedUserNostrWalletConnectUrl, amount, memo, userPublicKey, totalInSats, totalPrice, bitcoinPrice) {
    const transactionDate = new Date().toISOString();
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userPublicKey: userPublicKey,
            encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
            amount: amount,
            memo: memo,
            amount_in_sats: totalInSats,
            value_in_usd: totalPrice,
            usd_btc_exchange_rate: bitcoinPrice,
            flash_id: -1,
            transaction_date: transactionDate,
            user_public_key: userPublicKey
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to decrypt config');
    }

    return data;
}

const ECommerceCheckoutPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [userPublicKey, setUserPublicKey] = useState();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [service, setService] = useState();
    const [encryptedUserNostrWalletConnectUrl, setEncryptedUserNostrWalletConnectUrl] = useState();
    const [orderNumber, setOrderNumber] = useState();
    const [products, setProducts] = useState([]);
    const [apiKey, setApiKey] = useState();
    const [orderDetails, setOrderDetails] = useState({});
    const [totals, setTotals] = useState({
        subtotal: 0, 
        subtotalInSats: 0, 
        subtotalInZloty: 0, 
        totalTaxes: 0, 
        totalTaxesInSats: 0, 
        totalTaxesInZloty: 0, 
        total: 0, 
        totalInSats: 0, 
        totalInZloty: 0
    });

    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const [customerId, setCustomerId] = useState(null);
    
    // New state to track processing
    const [processing, setProcessing] = useState(false);

    // Referral Data
    const [isReferralExists, setIsReferralExists] = useState();
    const [referralData, setReferralData] = useState();
    const [referralWalletData, setReferralWalletData] = useState();

    // Loading state
    const [isLoading, setIsLoading] = useState();

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (params) {
            
            const orderNumber = params.get('orderNumber');
            const service = params.get('service');
            const apiKey = params.get('apiKey');
            const customer = JSON.parse(params.get('customer'));
            const products = JSON.parse(params.get('products'));

            // const orderNumber = decodeData(params.get('orderNumber'));
            // const apiKey = decodeData(params.get('apiKey'));
            // const customer = decodeData(params.get('customer'));
            // const products = decodeData(params.get('products'));
            
            setOrderNumber(orderNumber);
            setService(service);
            setApiKey(apiKey);
            setOrderDetails(customer);
            setProducts(products);

        }

    }, []);

    useEffect(() => {
        if (apiKey) {
            setIsLoading(true);
            const checkApiKey = async() => {
                const formData = new FormData();
                formData.append('api_key', apiKey);
                formData.append('service', service);

                const response = await fetch('https://api.paywithflash.com/ecommerce/api/check_api_key', {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Network was not ok!');
                }

                const data = await response.json();

                if (data.success) {
                    setEncryptedUserNostrWalletConnectUrl(data.data.nwc_url);
                    setUserPublicKey(data.data.user_public_key);
                }
                return data;
            }

            checkApiKey();
        }
    }, [apiKey]);

    useEffect(() => {
        if (userPublicKey) {
            const fetchReferralData = async () => {
                const referralData = await fetchReferralDataWithPublicKey(userPublicKey);
                setIsReferralExists(referralData.isReferralExists);
                setReferralData(referralData.referralData);

                if (referralData.isReferralExists) {
                    const referralWalletData = await fetchWalletUrlById(referralData.referralData.wallet_id);
                    setReferralWalletData(referralWalletData);
                }
            }

            fetchReferralData();
        }
    }, [userPublicKey]);

    useEffect(() => {
        if (products.length > 0) {
            calculateTotal()
            .then((data) => {
                setTotals(data);
            });
        }
    }, [products]);

    const handleOrderSubmit = (formValues) => {
        setIsFormSubmitted(true);
        setOrderDetails(formValues);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handlePaymentSuccess = async () => {
        if (processing) return; // Prevent duplicate processing
        setProcessing(true); // Set processing state

        setPaymentSuccess(true);
        try {

            const totals = await calculateTotal(); // Get the total values for the email
            const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars

            await sendPurchaseConfirmationEmail(products, orderDetails.email, totals, apiKey, service); // Send a single email with the updated cart

            const { subtotal, subtotalInSats, subtotalInZloty, totalTaxes, totalTaxesInSats, totalTaxesInZloty, total, totalInSats, totalInZloty } = totals;
            const customerID = await saveCustomerToDB(orderDetails, service);
            await saveSaleInfoToDB(customerID, totals, service);  // Ensure saveSaleInfoToDB is called after saving customer
            // Calculate the fee and price minus fee
            const feePercentage = 1.5; // 1%

            // Assuming totalInSats is already defined and is the total price in Satoshis
            let satsFee = totalInSats * (feePercentage / 100); // Calculate the fee
            satsFee = Math.round(satsFee); // Rounding to the nearest whole number

            let satsFeeToFlash, satsFeeToReferral;
            if (isReferralExists) {
                satsFeeToFlash = (satsFee * 70) / 100;
                satsFeeToReferral = (satsFee * 30) / 100;
                await payFeeToReferral(referralWalletData.nwc_url, userPublicKey, encryptedUserNostrWalletConnectUrl, satsFeeToReferral, `Referral Fee for Flash sale`, referralData, totalInSats, total, bitcoinPriceUSD);
            } else {
                satsFeeToFlash = satsFee;
            }

            // Pay fee to flash
            await payFeeToFlash(encryptedUserNostrWalletConnectUrl, satsFeeToFlash, `Fee for Flash sale`, userPublicKey, totalInSats, total, bitcoinPriceUSD);

            // coding here after payment success

        } catch (error) {
            console.error('Error during payment success handling:', error.message);
        } finally {
            setProcessing(false); // Reset processing state
        }
    };

    const handleBackClick = () => {
        // coding here when clicking the back button
    };

    const calculateTotal = async() => {
        let subtotal = 0;
        let subtotalInSats = 0;
        let subtotalInZloty = 0;

        let totalTaxes = 0;
        let totalTaxesInSats = 0;
        let totalTaxesInZloty = 0;

        if (products.length) {
            
            for (const product of products) {
                
                const { priceInDollars, priceInSatoshis, priceInZloty } = await convertPrices(product.Currency, product.Price);

                product.PriceInDollars = priceInDollars;
                product.PriceInSatoshis = priceInSatoshis;
                product.PriceInZloty = priceInZloty;
    
                const quantity = product.quantity || 1;
                const pricePerUnit = product.PriceInDollars || 0;
                const priceInSatoshisPerUnit = product.PriceInSatoshis || 0;
                const priceInZlotyPerUnit = product.PriceInZloty || 0;
    
                const taxRate = product.TaxRate ? parseFloat(product.TaxRate) / 100 : 0;
                
                let productTotal = 0.0, productTotalInSats = 0.0, productTotalInZloty = 0.0;

                if (product.Discount) {
                    productTotal = pricePerUnit * quantity * (1 - Number(product.Discount) / 100);
                    productTotalInSats = priceInSatoshisPerUnit * quantity * (1 - Number(product.Discount) / 100);
                    productTotalInZloty = priceInZlotyPerUnit * quantity * (1 - Number(product.Discount) / 100);
                }
                else {
                    productTotal = pricePerUnit * quantity;
                    productTotalInSats = priceInSatoshisPerUnit * quantity;
                    productTotalInZloty = priceInZlotyPerUnit * quantity;
                }

                const productTax = productTotal * taxRate;
                const productTaxInSats = productTotalInSats * taxRate;
                const productTaxInZloty = productTotalInZloty * taxRate;
    
                subtotal += productTotal;
                subtotalInSats += productTotalInSats;
                subtotalInZloty += productTotalInZloty;

                
                totalTaxes += productTax;
                totalTaxesInSats += productTaxInSats;
                totalTaxesInZloty += productTaxInZloty;
            };
            
            const total = subtotal + totalTaxes;
            const totalInSats = subtotalInSats + totalTaxesInSats;
            const totalInZloty = subtotalInZloty + totalTaxesInZloty;

            return { subtotal, subtotalInSats, subtotalInZloty, totalTaxes, totalTaxesInSats, totalTaxesInZloty, total, totalInSats, totalInZloty };
        }

    };

    const saveCustomerToDB = async (orderDetails, service) => {
        const jsonData = {
            email: orderDetails.email,
            first_name: orderDetails.firstName,
            last_name: orderDetails.LastName,
            address1: orderDetails.addressLine1 || '',
            address2: orderDetails.addressLine2 || '',
            postcode: orderDetails.postalCode || '',
            city: orderDetails.city || '',
            country: orderDetails.country || '',
            phone: orderDetails.phoneNumber || ''
        };

        try {
            const response = await axios.post('https://api.paywithflash.com/ecommerce/api/ecommerce_customers', jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'Service': service,
                }
            });
            if (response.data.success) {
                setCustomerId(response.data.data.customer_id);
            }
            else {
                throw new Error(response.data.error);
            }

            return response.data.data.customer_id;
        } catch (error) {
            console.error('Error saving the customer info to DB:', error.response ? error.response.data : error.message);
            throw error;
        }
    };

    const saveSaleInfoToDB = async (customerID, totals, service) => {
        const payload = {
            'products': products,
            'ecommerce_order_id': orderNumber,
            'customer_id': customerID,
            'totals': totals,
            'sale_date': new Date().toISOString(),
        };

        try {

            const response = await axios.post('https://api.paywithflash.com/ecommerce/api/ecommerce_orders', payload, {
            headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'Service': service,
                }
            });
            // console.log('Sale info saved to DB:', response.data);
        } catch (error) {
            console.error('Error saving the sale information:', error.response ? error.response.data : error.message);
            throw error;
        }
    };

    return (
        <>
            { isLoading && (
                <div className="loading-container absolute inset-0 flex items-center justify-center bg-black bg-opacity-60" style = {{ zIndex : '100'}}>
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12 mb-4"></div>
                </div>
            )}
            <div className='hidden md:block'>
            </div>
            <div className="bg-white min-h-screen flex flex-col">
                <div className="relative flex-grow">
                    <div className="absolute left-0 top-0 h-full w-1/2 bg-white hidden lg:block" aria-hidden="true" />
                    <div className="absolute right-0 top-0 h-full w-1/2 bg-gray-900 hidden lg:block" aria-hidden="true" />

                    <div className="relative mx-auto max-w-7xl grid grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 lg:pt-16">
                        <h1 className="sr-only">Checkout</h1>

                        <section
                            aria-labelledby="summary-heading"
                            className="bg-gray-800 py-12 text-gray-300 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pb-24 lg:pt-0"
                        >
                            <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                                <h2 id="summary-heading" className="sr-only">
                                    Order summary
                                </h2>

                                <dl>
                                    <dt className="text-sm font-medium">Amount due</dt>
                                    <dd className="mt-1 text-3xl font-bold tracking-tight text-white">${totals.total.toFixed(2)}</dd>
                                    <dd className="text-xs font-medium text-white">{totals.totalInSats.toLocaleString()} sats</dd>
                                </dl>

                                <ul role="list" className="divide-y divide-white divide-opacity-10 text-sm font-medium">
                                    {Array.isArray(products) && products.map((product, index) => (
                                        <li key={index} className="flex items-start space-x-4 py-6">
                                            <img
                                                src={product.ImageURL}
                                                alt={product.Name}
                                                className="h-20 w-20 flex-none rounded-md object-cover object-center"
                                            />
                                            <div className="flex-auto space-y-1">
                                                <h3 className="text-white">{product.Name}</h3>
                                                <p>{product.color}</p>
                                                <p>{product.size}</p>
                                                <p>Quantity: {product.quantity}</p>
                                                <p>Price per unit: ${product.PriceInDollars?.toFixed(2)} USD</p>
                                                <p className="text-sm text-gray-500">{product.PriceInSatoshis?.toLocaleString()} sats</p>
                                            </div>
                                            <div>
                                                <p className="flex-none text-base font-medium text-white">${(product.PriceInDollars * (product.quantity || 1)).toFixed(2)} USD</p>
                                                <p className="flex-none text-sm text-gray-500">{(product.PriceInSatoshis * (product.quantity || 1)).toLocaleString()} sats</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <dl className="space-y-6 border-t border-white border-opacity-10 pt-6 text-sm font-medium">
                                    <div className="flex items-center justify-between">
                                        <dt>Subtotal</dt>
                                        <div className='justify-right'>
                                            <dd>${totals.subtotal.toFixed(2)}</dd>
                                            <dd className="text-sm text-gray-500">{totals.subtotalInSats.toFixed(0).toLocaleString()} sats</dd>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <dt>Taxes</dt>
                                        <div>
                                            <dd>${totals.totalTaxes.toFixed(2)}</dd>
                                            <dd className="text-sm text-gray-500">{totals.totalTaxesInSats.toFixed(0).toLocaleString()} sats</dd>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white">
                                        <dt className="text-base">Total</dt>
                                        <div>
                                            <dd className="text-base">${totals.total.toFixed(2)}</dd>
                                            <dd className="text-sm text-gray-500">{totals.totalInSats.toFixed(0).toLocaleString()} sats</dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </section>

                        <div className='mt-4 lg:col-start-1 lg:row-start-1'>
                            <button
                                onClick={handleBackClick}
                                className="ml-8 mb-4 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                            >
                                Back to Store
                            </button>

                            <section
                                aria-labelledby="payment-and-shipping-heading"
                                className="py-16 lg:mx-auto lg:w-full lg:max-w-lg lg:pb-24 lg:pt-0"
                            >
                                <h2 id="payment-and-shipping-heading" className="sr-only">
                                    Payment and shipping details
                                </h2>

                                <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                                    <div className="bg-white rounded-xl shadow-lg p-8">
                                        <Payment
                                            products={products}
                                            user={userPublicKey}
                                            encryptedUserNostrWalletConnectUrl={encryptedUserNostrWalletConnectUrl}
                                            totalInSats={totals.totalInSats.toFixed(0)}
                                            orderDetails={orderDetails}
                                            handlePaymentSuccess={handlePaymentSuccess}
                                            setIsLoading={setIsLoading}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-center space-x-1 mt-2">
                                    <p className="text-gray-500 text-xs">
                                        Don't have a compatible wallet?
                                    </p>
                                    <p className="text-gray-500 font-bold text-xs underline cursor-pointer">
                                        <a href="https://getalby.com/invited-by/pierrecorbin"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-gray-500 hover:text-gray-800">
                                            Setup in 2 min with our partner
                                        </a>
                                    </p>
                                </div>
                                <div className="flex justify-center space-x-1 mt-1">
                                    <span className="text-gray-500 text-xs cursor-pointer" onClick={toggleModal}>
                                        Don't have Bitcoin?
                                    </span>
                                    <span className="text-gray-500 font-bold text-xs cursor-pointer underline hover:text-gray-800" onClick={toggleModal}>
                                        Get some!
                                    </span>
                                </div>
                                {isModalOpen && (
                                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={toggleModal}>
                                        <div className="bg-white rounded-lg p-4" style={{ width: '90%', maxWidth: '600px', height: '80%', overflowY: 'auto' }} onClick={e => e.stopPropagation()}>
                                            <iframe
                                                allow="usb; ethereum; clipboard-write; payment; microphone; camera"
                                                loading="lazy"
                                                src="https://widget.mtpelerin.com/?_ctkn=4e7165a8-2f93-4db0-8895-b5ca05330e65&type=web&lang=en&tab=buy&tabs=buy,sell,swap&net=lightning_mainnet&nets=lightning_mainnet&primary=%23222D3A&success=%23222D3A&mylogo=https%3A%2F%2Fflash-images.fra1.cdn.digitaloceanspaces.com%2Fflash_images%2Fflash%2520logo.png&bsc=USD&bdc=sat"
                                                title="Mt Pelerin exchange widget"
                                                style={{ width: '100%', height: '100%', border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </div>
                                )}
                                <div className="mt-10 mb-4 text-xs text-center text-slate-500 py-2 lg:col-span-2">Powered by <a href="https://paywithflash.com" className="font-bold text-slate-500">Flash</a></div>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ECommerceCheckoutPage;
