import React, { useEffect, useState, Fragment } from 'react'; // Make sure useState is imported here
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Login from '../pages/LoginPage';
import PleaseVerify from '../pages/PleaseVerifyPage';
import VerifySuccess from '../pages/VerifySuccessPage';
import PasswordResetRequest from '../pages/passwordReset/PasswordResetRequest';
import ResetPassword from '../pages/passwordReset/ResetPassword';
import Navbar from './navbar';
import Footer from './Footer';
import Documentation from '../pages/Documentation';
import Dashboard from '../pages/Dashboard';
import About from '../pages/About';
import Support from '../pages/Support';
import Vectorstores from '../pages/Vectorstores';
import Home from '../pages/Home';
import NostrNests from '../pages/nostrnests';
import VectorEggs from '../pages/VectorEggs';
import MyVectorEggs from '../pages/MyVectorEggs';
import AutoGenerateKeys from '../pages/AutoGenerateKeys';
import VectorstoreDetailView from '../pages/VectorstoreDetailView';
import Eggs from '../pages/Eggs';
import EggDetailView from '../pages/EggDetailView';
import PaywallCreator from '../pages/PaywallCreator';
import SnippetDisplay from '../pages/SnippetDisplay';
import generateSnippet from '../pages/generateSnippet';
import MyServices from '../pages/MyServices';
import MySubscriptions from '../pages/MySubscriptions';
import ServiceDetail from '../pages/ServiceDetailPage';
import SubscriptionDetail from '../pages/SubscriptionDetailPage';
import NewEgg from '../pages/NewEgg'
import NewFlash from '../pages/NewFlash'
import MyAccount from '../pages/MyAccount'
import UrlPaymentPage from '../pages/UrlPaymentPage'
import PointOfSalePage from '../pages/PointOfSalePage'
import ExternalAppLoginPage from '../pages/ExternalAppLoginPage'
import SubscriptionPage from '../pages/SubscriptionPage'
import ManageSubscriptionPage from '../pages/ManageSubscriptionPage'
import ExternalAppLoginPageVimeoOTT from '../pages/ExternalAppLoginPageVimeoOTT'
import ExternalAppLoginPageGeyser from '../pages/ExternalAppLoginPageGeyser'
import NewSubscriptions from '../pages/NewSubscription';
import Subscriptions from '../pages/Subscriptions';
import ProductListPage from '../pages/ProductListPage';
import CustomersPage from '../pages/CustomersPage';
import ProductPage from '../pages/ProductPage';
import CheckoutPage from '../pages/CheckoutPage';

import ProductGallery from '../pages/ProductGallery';
import ProductGalleryCheckoutPage from '../pages/ProductGalleryCheckoutPage';
import ProductGallerySuccessScreen from '../pages/ProductGallerySuccessScreen';
import ProductGalleryProductPage from '../pages/ProductGalleryProductPage';
import ProductGalleryProductUserPage from '../pages/ProductGalleryProductUserPage';

import ProductGalleryUserPage from '../pages/ProductGalleryUserPage';
import ProductGalleryCheckoutUserPage from '../pages/ProductGalleryCheckoutUserPage';
import ProductGallerySuccessUserScreen from '../pages/ProductGallerySuccessUserScreen';

import BitcoinFilmFestDashboardPage from '../pages/BitcoinFilmFestDashboardPage';
import BitcoinFilmFestDetailPage from '../pages/BitcoinFilmFestDetailPage';
import BitcoinFilmFestAgendaPage from '../pages/BitcoinFilmFestAgendaPage';
import Transactions from '../pages/Transactions';

// E-Commer plugin
import ECommercePage from '../pages/ECommercePage';
import ECommerceCheckoutPage from '../pages/ECommerceCheckoutPage';
// Parallel Landing Page
import LandingPageWithParallelEConomy from '../pages/ParallelEConomy/LandingPageWithParallelEConomy';
import VerifySuccessPageForParallelEconomy from '../pages/ParallelEConomy/VerifySuccessPage';

// Test pages
import PaywallTestPage from '../pages/testPages/PaywallTestPage';
import ButtonTestPage from '../pages/testPages/ButtonTestPage'
import VideoTestPage from '../pages/testPages/VideoTestPage'
import SubscribeTestPage from '../pages/testPages/SubscribeTestPage'

//Geyser Project list page
import Geyser from '../pages/Geyser';



import Income from '../pages/Income';
import SalesReporting from '../pages/SalesReporting';
import Expenses from '../pages/Expenses';
import FlashAPIForm from './FlashForms/forms/FlashAPIForm'; // Import your form component
import ConnectAPIForm from './FlashForms/forms/ConnectAPIForm'; // Import your form component
import { Dialog, Transition } from '@headlessui/react';

function RoutesWrapper() {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = localStorage.getItem('userHexPrivateKey');
  const [snippet, setSnippet] = useState('');

  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const openModal = (actionIdentifier) => {
    setSelectedAction(actionIdentifier);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedAction(null);
  };

  const getFormComponent = () => {
    switch (selectedAction) {
      case 'flashAPI':
        return <FlashAPIForm close={closeModal} />;
      case 'connectAPI':
        return <ConnectAPIForm close={closeModal} />;
      // Add cases for other forms
      default:
        return null; // Or some default content
    }
  };

  useEffect(() => {
    // Check if the current path starts with '/pay/'
    const isPayPath = location.pathname.startsWith('/pay/');
    // Check if the current path starts with '/external-login/'
    const isExternalLoginPath = location.pathname.startsWith('/external-login');
    // Check if the current path starts with '/subscription-page/'
    const isSubscriptionPath = location.pathname.startsWith('/subscription-page');
    // Check if the current path starts with '/manage-subscription/'
    const isManageSubscriptionPath = location.pathname.startsWith('/manage-subscription');
    // Check if the current path start with '/verify/'
    const isVerifySuccess = location.pathname.startsWith('/verify');
    // Check if the current path start with '/please-verify/'
    const isPleaseVerify = location.pathname.startsWith('/please-verify');
    // Check if the current path starts with '/reset-password/'
    const isResetPasswordPath = location.pathname.startsWith('/reset-password');
    // Check if the current path starts with '/reset-password/'
    const isResetPasswordRequestPath = location.pathname.startsWith('/password-reset-request');
    // Check if the current path starts with '/geyser/
    const isGeyser = location.pathname.startsWith('/geyser');
    // Check if the current path ends with '-test'
    const isTestPath = location.pathname.endsWith('-test');
    // Check if the current path ends with 'product-page'
    const isProductPagePath = location.pathname.startsWith('/product-page/');
    // Check if the current path ends with 'checkout-page'
    const isCheckoutPagePath = location.pathname.startsWith('/checkout-page/');
    // Check if the current path starts with '/external-login/'
    const isFilmFestPath = location.pathname.startsWith('/bitcoin-filmfest');
    // Check if the current path starts with '/product-gallery/'
    const isProductGalleryPath = location.pathname.startsWith('/product-gallery');
    // Check if the current path starts with '/e-commerce-checkout/'
    const isECommerceCheckoutPath = location.pathname.startsWith('/e-commerce-checkout');
  
    // Check if the current path start with '/landing-page-with-parallel-economy/
    const isLandingPageWithParallelEConomy = location.pathname.startsWith('/landing-page-with-parallel-economy');
    const isVerifySuccessPageForParallelEconomy = location.pathname.startsWith('/verify-token');

    // Redirect to login page if not authenticated and not on a '/pay/' path
    if (!isAuth && !isPayPath && !isExternalLoginPath && !isSubscriptionPath && !isManageSubscriptionPath && !isVerifySuccess && !isPleaseVerify && !isResetPasswordPath && !isResetPasswordRequestPath && !isTestPath && !isGeyser && !isProductPagePath && !isCheckoutPagePath && !isFilmFestPath && !isProductGalleryPath && !isLandingPageWithParallelEConomy && !isVerifySuccessPageForParallelEconomy && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [isAuth, location, navigate]);

  const handleGenerateSnippet = (url, price) => {
    const newSnippet = generateSnippet(url, price);
    setSnippet(newSnippet);
  };

  return (
        <Fragment> {/* Start of Fragment */}

    <Routes>
      <Route path="/" element={<Dashboard />} />
      {/* <Route path="/" element={
          <>
            <Navbar />
            <Home />
            <Footer />
          </> 
        } /> */}
        <Route path="/login" element={<Login />} />
        <Route path='/please-verify' element={<PleaseVerify />} />
        <Route path='/verify/:token' element={<VerifySuccess />} />
        <Route path="/password-reset-request" element={<PasswordResetRequest />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/auto-generate-keys" element={<AutoGenerateKeys />} />
        <Route path="/vectorstore-detail-view/:vectorstoreID" element={<VectorstoreDetailView />} />
        <Route path="/nostrnests" element={<NostrNests />} />
        <Route path="/my-services" element={<MyServices />} />
        <Route path="/my-subscriptions" element={<MySubscriptions />} />
        <Route path="/service-details/:flashId" element={<ServiceDetail />} />
        <Route path="/subscription-details/:flashId" element={<SubscriptionDetail />} />
        <Route path="/nostreggs" element={<Eggs />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/egg-detail-view" element={<EggDetailView />} />
        <Route path="/vectornests" element={<VectorEggs />} />
        <Route path="/income" element={<Income />} />
        <Route path="/sales" element={<SalesReporting />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/product-list-page" element={<ProductListPage />} />
        <Route path="/customers" element={<CustomersPage />} />
        <Route path="/product-page/:productId" element={<ProductPage />} />
        <Route path="/checkout-page/:productId" element={<CheckoutPage />} />

        <Route path="/product-gallery" element={<ProductGallery />} />
        <Route path="/product-gallery-checkout" element={<ProductGalleryCheckoutPage />} />
        <Route path="/product-gallery-success" element={<ProductGallerySuccessScreen />} />
        <Route path="/product-gallery-product" element={<ProductGalleryProductPage />} />

        <Route path="/product-gallery-user" element={<ProductGalleryUserPage />} />
        <Route path="/product-gallery-checkout-user" element={<ProductGalleryCheckoutUserPage />} />
        <Route path="/product-gallery-success-user" element={<ProductGallerySuccessUserScreen />} />
        <Route path="/product-gallery-product-user" element={<ProductGalleryProductUserPage />} />

        <Route path="/bitcoin-filmfest" element={<BitcoinFilmFestDashboardPage />} />
        <Route path="/bitcoin-filmfest/:player" element={<BitcoinFilmFestDetailPage />} />
        <Route path="/bitcoin-filmfest-agenda" element={<BitcoinFilmFestAgendaPage />} />
        <Route path="/transactions" element={<Transactions />} />


        <Route path="/myvectornests" element={<MyVectorEggs />} />
        <Route path="/new-subscriptions" element={<NewSubscriptions />} />

      <Route path="/new-flash" element={<NewFlash />} />
      <Route path="/new-egg" element={<NewEgg />} />
      <Route path="/vectorstore-detail-view" element={<VectorstoreDetailView />} />
      <Route path="/dashboard" element={<Dashboard />} />
      
      {/* Landing page with Parallel Economy */}
      <Route path="/landing-page-with-parallel-economy" element={<LandingPageWithParallelEConomy />} />
      <Route path="/verify-token/:token" element={<VerifySuccessPageForParallelEconomy />} />

      {/* E-commerce pages */}
      <Route path="/e-commerce" element={<ECommercePage />} />
      <Route path="/e-commerce-checkout" element={<ECommerceCheckoutPage />} />
      
      {/* Test pages */}
      <Route path="/paywall-test" element={<PaywallTestPage />} />
      <Route path="/button-test" element={<ButtonTestPage />} />
      <Route path="/video-test" element={<VideoTestPage />} />
      <Route path="/subscribe-test" element={<SubscribeTestPage />} />
      
      {/* Project list page */}
      <Route path = "/geyser" element={<Geyser />} />

      <Route path="/external-login" element={<ExternalAppLoginPage />} />
      <Route path="/subscription-page" element={<SubscriptionPage />} />
      <Route path="/manage-subscription" element={<ManageSubscriptionPage />} />
      <Route path="/external-login/vimeo_ott" element={<ExternalAppLoginPageVimeoOTT />} />
      <Route path="/external-login/geyser" element={<ExternalAppLoginPageGeyser />} />
      


      <Route path="/pay/:usernameOrPublicKey" element={<UrlPaymentPage />} />
      <Route path="/point-of-sale" element={<PointOfSalePage />} />

       <Route path="/paywall-creator" element={
        <>
          <Navbar />
          <PaywallCreator onGenerateSnippet={handleGenerateSnippet} />
          {snippet && <SnippetDisplay snippet={snippet} />}
          <Footer />
        </>
      } />
        <Route path="/vectorstores" element={
          <>
            <Navbar />
            <Vectorstores />
            <Footer />
          </>
        } />
        <Route path="/documentation" element={
          <>
            <Navbar />
            <Documentation />
            <Footer />
          </>
        } />
        <Route path="/support" element={
          <>
            <Navbar />
            <Support />
            <Footer />
          </>
        } />
        <Route path="/about" element={
          <>
            <Navbar />
            <About />
            <Footer />
          </>
        } />
    </Routes>
      {/* Modal with dynamic form based on the action */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          {/* Modal inner content */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child as={Fragment}>
                <Dialog.Panel className="rounded-lg bg-white shadow-xl">
                  {getFormComponent()}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment> 
  );
}

export default RoutesWrapper;
