import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPageWithParallelEConomy = () => {

    const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
    const navigate = useNavigate();

    const [email, setEmail] = useState();
    const [isSentVerificationLink, setIsSentVerificatinLink] = useState(false);

    // status messsage
    const [status, setStatus] = useState();

    const sendNotification = async (e) => {
        e.preventDefault();

        if (email) {

            const formData = new FormData();
            formData.append('email', email);

            try {
                const response1 = await fetch('https://api.paywithflash.com/api/check_email', {
                    method: 'POST',
                    body: formData,
                });

                if (!response1.ok) {
                    throw new Error('Network was not ok!');
                }

                const data1 = await response1.json();

                if (data1.email_exists) {
                    setStatus('Email already exists');
                    return ;
                }

                const response2 = await fetch('https://api.paywithflash.com/api/send_verification_link', {
                    method: 'POST',
                    body: formData,
                });

                if (!response2.ok) {
                    throw new Error("Network is not ok!");
                }

                const data2 = await response2.json();

                if (data2.success) {
                    setIsSentVerificatinLink(data2.success);
                    setStatus("Please check your email. If you haven't received the email, please check your email again.");
               }

            } catch (error) {
                console.error(error);
                setStatus("Please check your internet connection");
            } finally {
                setTimeout(() => {
                    setStatus('');
                }, 3000)
            }
        }
        else {
            setStatus("Please input the email address");
            return;
        }
    }

    return (
        <>
            <div className="flex h-5/6 h-auto md:h-screen" style={{backgroundImage: "url('nimages/bg-flash.png')", backgroundPosition: 'top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                <div className="flex flex-col justify-center pt-4 md:pt-0  px-4 md:px-36 text-white w-full align-middle items-center " >
                    <div className="w-full lg:w-2/3 xl:w-1/3  border-slate-200 rounded-md p-7 pb-4 shadow-lg bg-white">
                        <div className="flex grid grid-cols-3 gap-3 items-center">
                            <img className="w-40 mb-3 -ml-1 -mt-0.5" src="/nimages/logo-org.png"></img>
                            <div></div>
                            <img className="w-40 mb-3 -mr-1 -mt-0.5" src="https://www.paralleleconomy.com/wp-content/uploads/2022/01/logo-white-shadow.png"></img>
                        </div>
                        <div className="items-center mb-0">
                            <div className="mt-2">
                                <form className="space-y-3" onSubmit={sendNotification}>
                                    <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-slate-900">
                                        Email
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder='satoshi@nakamoto.com'
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="mt-1 block w-full rounded-md bg-white/5 py-1.5 text-slate-900 border border-slate-200 ring-1 ring-inset ring-white/10 sm:text-sm sm:leading-6"
                                    />
                                    </div>
                                    <div>
                                        {/* {errorMessage && <div className="text-red-500 mt-1 text-sm">{errorMessage}</div>} */}
                                    </div>
                                    <div>
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md bg-slate-900 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    >
                                        Confirm
                                    </button>
                                    { status && (
                                        <div className="flex justify-center mt-2">
                                            <div className="text-green-600">{status}</div>
                                        </div>
                                    )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </>
    )
}

export default LandingPageWithParallelEConomy;
