import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProductOrderForm from '../components/Products/Products/ProductOrderForm';
import CartPayment from '../components/Products/ProductGallery/CartPayment';
import ProductOrderFormDisplay from '../components/Products/Products/ProductOrderFormDisplay';
import SuccessScreen from './ProductGallerySuccessScreen';
import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';

const encodeData = (data) => {
    const jsonString = JSON.stringify(data);
    return encodeURIComponent(btoa(unescape(encodeURIComponent(jsonString))));
};

export const decodeCart = (encodedCart) => {
    if (!encodedCart) return [];
    try {
        return JSON.parse(decodeURIComponent(escape(atob(decodeURIComponent(encodedCart)))));
    } catch (e) {
        console.error('Failed to decode cart:', e);
        return [];
    }
};

const convertPrices = async (currency, value) => {
    const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars
    const exchangeRate = await getExchangeRateFromDollars('PLN');

    if (currency === "usd") {
        const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
        const priceInZloty = parseFloat(value) * exchangeRate;
        return { priceInDollars: parseFloat(value), priceInSatoshis, priceInZloty };
    } else if (currency === "satoshis") {
        const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
        const priceInZloty = priceInDollars * exchangeRate;
        return { priceInDollars: parseFloat(priceInDollars), priceInSatoshis: value, priceInZloty };
    } else if (currency === 'zloty') {
        const priceInDollars = parseFloat(value) / exchangeRate;
        const priceInSatoshis = Math.round((priceInDollars / bitcoinPriceUSD) * 100000000);
        return { priceInDollars, priceInSatoshis, priceInZloty: parseFloat(value) };
    }
};

async function getBitcoinPrice() {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    return data;
}

async function getExchangeRateFromDollars(to_currency) {
    const formData = new FormData();
    formData.append('to_currency', to_currency);
    const response = await fetch('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
        method: 'POST',
        body: formData,
    });
    const data = await response.json();
    return data;
}

async function sendPurchaseConfirmationEmail(products, recipientEmail, totals) {
    const apiUrl = 'https://api.paywithflash.com/api/send_purchase_confirmation_for_cart';

    const requestData = {
        products: products,
        recipient_email: recipientEmail,
        totals: totals // Include totals in the email data
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to send purchase confirmation: ${errorData.error || response.status}`);
        }
        // console.log('Email sent successfully');
    } catch (error) {
        console.error('Error sending purchase confirmation:', error.message);
        throw error;
    }
}

async function createAndUploadZip(product) {
    try {
        const response = await axios.post('https://api.paywithflash.com/api/create_zip_endpoint', {
            product_id: product.ProductID,
            file_urls: product.DigitalProductURLs // Assuming you have URLs ready in the product object
        });
        // console.log('Zip created successfully');
        return response.data.url; // Assuming the API returns an object with a url property
    } catch (error) {
        console.error('Failed to create zip file:', error);
        throw new Error('Failed to create download link.');
    }
}

async function updateInventoryAfterPurchase(productId, quantity) {
    const url = 'https://api.paywithflash.com/api/update_inventory_after_purchase';
    const data = {
        product_id: productId,
        quantity: quantity
    };

    return axios.post(url, data)
        .then(response => {
            // console.log('Inventory updated successfully');
            return response.data;
        })
        .catch(error => {
            console.error('Error updating inventory:', error.response ? error.response.data : error.message);
            throw error;
        });
}

const fetchUserData = async(user_public_key) => {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/get_user_by_public_key?user_public_key=${encodeURIComponent(user_public_key)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
}

async function fetchReferralDataWithPublicKey(userPublicKey) {
    const formData = new FormData();
    formData.append('userPublicKey', userPublicKey);
    try {
        const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

async function fetchWalletUrlById(wallet_id) {
    try {
        const url = new URL('https://api.paywithflash.com/api/get_wallet_from_id');
        url.searchParams.append('wallet_id', wallet_id);

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

async function payFeeToReferral(encryptedReferralUserNostrWalletConnectUrl, userNostrWalletConnectUrl, amount, memo, referralData, user, totalInSats, totalPrice, bitcoinPrice) {
    const transactionDate = new Date().toISOString();
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_referral_with_nwc', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            referralUserPublicKey: referralData.user_public_key,
            encryptedReferralUserNostrWalletConnectUrl: encryptedReferralUserNostrWalletConnectUrl,
            userPublicKey: user,
            userNostrWalletConnectUrl: userNostrWalletConnectUrl,
            amount: amount,
            memo: memo,
            amount_in_sats: totalInSats,
            value_in_usd: totalPrice,
            usd_btc_exchange_rate: bitcoinPrice,
            flash_id: -1,
            transaction_date: transactionDate,
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to pay referral');
    }

    return data;
}

async function payFeeToFlash(userNostrWalletConnectUrl, amount, memo, user, totalInSats, totalPrice, bitcoinPrice) {
    const transactionDate = new Date().toISOString();
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash_with_nwc', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userPublicKey: user,
            userNostrWalletConnectUrl: userNostrWalletConnectUrl,
            amount: amount,
            memo: memo,
            amount_in_sats: totalInSats,
            value_in_usd: totalPrice,
            usd_btc_exchange_rate: bitcoinPrice,
            flash_id: -1,
            transaction_date: transactionDate,
            user_public_key: user
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to decrypt config');
    }

    return data;
}

const ProductGalleryCheckoutPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const cart = location.state?.cart || [];
    const user = localStorage.getItem('userHexPublicKey');

    const encryptedParallelEConomyNwcUrl = process.env.REACT_APP_PARALLEL_ECONOMY_NWC_URL;
    const parallelEConomyPublicKey = process.env.REACT_APP_PARALLEL_ECONOMY_NOSTR_PUBLIC_KEY;

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [isDigitalOnly, setIsDigitalOnly] = useState(true);
    const [customerId, setCustomerId] = useState(null);
    const [processing, setProcessing] = useState(false); // New state to track processing

    const [userFee, setUserFee] = useState();

    // Referral Data
    const [isReferralExists, setIsReferralExists] = useState();
    const [referralData, setReferralData] = useState();
    const [referralWalletData, setReferralWalletData] = useState();

    useEffect(() => {
        if (user) {
            const getUserData = async() => {
                const userData = await fetchUserData(user);
                setUserFee(userData.fee);
            }
            
            const fetchReferralData = async () => {
                const referralData = await fetchReferralDataWithPublicKey(user);
                setIsReferralExists(referralData.isReferralExists);
                setReferralData(referralData.referralData);

                if (referralData.isReferralExists) {
                    // speical processing for parallel economy
                    if (!referralData.referralData.wallet_id) {
                        const referralWalletData = {};
                        referralWalletData.nwc_url = encryptedParallelEConomyNwcUrl;
                        setReferralWalletData(referralWalletData);
                    }
                    else {
                        const referralWalletData = await fetchWalletUrlById(referralData.referralData.wallet_id);
                        setReferralWalletData (referralWalletData);
                    }
                }
            }
            getUserData();
            fetchReferralData();
        }
    }, [user]);

    useEffect(() => {
        const checkIfDigitalOnly = cart.every(product => product.is_digital_product);
        setIsDigitalOnly(checkIfDigitalOnly);
    }, [cart]);

    const handleOrderSubmit = (formValues) => {
        setIsFormSubmitted(true);
        setOrderDetails(formValues);
    };

    const handleModify = () => {
        setIsFormSubmitted(false);
        setOrderDetails({});
    };

    const emptyCart = () => {
        const newCart = [];
        localStorage.setItem('cart', JSON.stringify(newCart));
    };

    const handlePaymentSuccess = async () => {
        const nostrWalletConnectUrl = cart[0]?.wallet_nwc;

        if (processing) return; // Prevent duplicate processing
        setProcessing(true); // Set processing state

        setPaymentSuccess(true);
        try {
            if (cart.some(product => product.inventory.StockCount)) {
                for (const product of cart) {
                    if (product.inventory.StockCount) {
                        await updateInventoryAfterPurchase(product.ProductID, product.quantity);
                    }
                }
            }

            // Collect download links for digital products
            const updatedCart = [...cart];
            for (const product of updatedCart) {
                if (product.is_digital_product) {
                    const downloadUrl = await createAndUploadZip(product);
                    product.download_link = downloadUrl; // Add the download link to the product
                }
            }

            const totals = calculateTotal(); // Get the total values for the email
            await sendPurchaseConfirmationEmail(updatedCart, orderDetails.email, totals); // Send a single email with the updated cart

            const { subtotal, subtotalInSats, totalTaxes, totalTaxesInSats, total, totalInSats, totalInZloty } = totals;
            const customerID = await saveCustomerToDB(orderDetails);
            await saveSaleInfoToDB(customerID);  // Ensure saveSaleInfoToDB is called after saving customer

            // Calculate the fee and price minus fee
            const feePercentage = userFee; // 1%

            // Assuming totalInSats is already defined and is the total price in Satoshis
            let satsFee = totalInSats * (feePercentage / 100); // Calculate the fee
            satsFee = Math.round(satsFee); // Rounding to the nearest whole number

            let satsFeeToFlash, satsFeeToReferral;
            if (isReferralExists) {
                const payPercent = referralData.pay_percent;
                satsFeeToFlash = satsFee * (100 - payPercent) / 100;
                satsFeeToReferral = satsFee * payPercent / 100;
                await payFeeToReferral(referralWalletData.nwc_url, nostrWalletConnectUrl, satsFeeToReferral, `Referral Fee for Flash sale`, referralData);
            } else {
                satsFeeToFlash = satsFee;
            }

            // Pay fee to flash
            await payFeeToFlash(nostrWalletConnectUrl, satsFeeToFlash, `Fee for Flash sale`);

            // Empty the cart
            emptyCart();

            navigate(`/product-gallery-success-user`, { state: { cart, user, orderDetails, subtotal, subtotalInSats, totalTaxes, totalTaxesInSats, total, totalInSats, totalInZloty } });
        } catch (error) {
            console.error('Error during payment success handling:', error.message);
        } finally {
            setProcessing(false); // Reset processing state
        }
    };

    const handleBackClick = () => {
        const encodedCart = encodeData(cart);
        const userPublicKey = user.replace(/"/g, ''); // Remove any quotation marks
        navigate(`/product-gallery-user?user=${userPublicKey}`);
    };

    const calculateTotal = () => {
        let subtotal = 0;
        let subtotalInSats = 0;
        let totalTaxes = 0;
        let totalTaxesInSats = 0;
        let totalInSats = 0;

        cart.forEach(product => {
            const quantity = product.quantity || 1;
            const pricePerUnit = product.PriceInDollars || 0;
            const priceInSatoshisPerUnit = product.PriceInSatoshis || 0;
            const taxRate = product.TaxRate ? parseFloat(product.TaxRate) / 100 : 0;
            const productTotal = pricePerUnit * quantity;
            const productTotalInSats = priceInSatoshisPerUnit * quantity;
            const productTax = productTotal * taxRate;
            const productTaxInSats = productTotalInSats * taxRate;

            subtotal += productTotal;
            subtotalInSats += productTotalInSats;
            totalTaxes += productTax;
            totalTaxesInSats += productTaxInSats;
        });

        const total = subtotal + totalTaxes;
        totalInSats = subtotalInSats + totalTaxesInSats;
        const totalInZloty = (total * cart[0].PriceInZloty / cart[0].PriceInDollars).toFixed(2);
        return { subtotal, subtotalInSats, totalTaxes, totalTaxesInSats, total, totalInSats, totalInZloty };
    };

    const { subtotal, subtotalInSats, totalTaxes, totalTaxesInSats, total, totalInSats, totalInZloty } = calculateTotal();

    const saveCustomerToDB = async (orderDetails) => {
        const jsonData = {
            user_public_key: orderDetails.npub || '',
            email: orderDetails.email,
            firstName: orderDetails.customerName,
            lastName: orderDetails.customerSurname,
            address1: orderDetails.addressLine1 || '',
            address2: orderDetails.addressLine2 || '',
            postalCode: orderDetails.postalCode || '',
            city: orderDetails.city || '',
            country: orderDetails.country || '',
            phoneNumber: orderDetails.phoneNumber || ''
        };

        try {
            const response = await axios.post('https://api.paywithflash.com/api/create_customer', jsonData);
            setCustomerId(response.data.CustomerID);
            // console.log('Customer saved with ID:', response.data.CustomerID);

            return response.data.CustomerID;
        } catch (error) {
            console.error('Error saving the customer info to DB:', error.response ? error.response.data : error.message);
            throw error;
        }
    };

    const saveSaleInfoToDB = async (customerID) => {
        const formData = new FormData();
        cart.forEach(product => {
            formData.append('product_list[]', product.ProductID);
            formData.append('product_quantity[]', product.quantity);
            formData.append('product_info[]', JSON.stringify(product));
        });
        formData.append('customer_id', customerID);
        formData.append('sale_date', new Date().toISOString());
        formData.append('sale_origin', 'online');
        formData.append('price_in_dollars', parseFloat(total).toFixed(2));
        formData.append('price_in_sats', parseInt(totalInSats, 10));
        formData.append('price_in_zloty', parseFloat(totalInZloty).toFixed(2));
        formData.append('subtotal', parseFloat(subtotal).toFixed(2));
        formData.append('subtotal_in_sats', parseInt(subtotalInSats, 10));
        formData.append('total_taxes', parseFloat(totalTaxes).toFixed(2));
        formData.append('total_taxes_in_sats', parseInt(totalTaxesInSats, 10));

        try {

            const response = await axios.post('https://api.paywithflash.com/api/save_cart_sale_info_to_db', formData);
            // console.log('Sale info saved to DB:', response.data);
        } catch (error) {
            console.error('Error saving the sale information:', error.response ? error.response.data : error.message);
            throw error;
        }
    };

    return (
        <SidebarLayout>
            <div className='hidden md:block'>
                <Header
                    title="My Store"
                    subtitle="Generate QR Code & Pay via QR Code"
                    showWalletsAvatar={false}
                />
            </div>
            <div className="bg-white min-h-screen flex flex-col">
                <div className="relative flex-grow">
                    <div className="absolute left-0 top-0 h-full w-1/2 bg-white hidden lg:block" aria-hidden="true" />
                    <div className="absolute right-0 top-0 h-full w-1/2 bg-gray-900 hidden lg:block" aria-hidden="true" />

                    <div className="relative mx-auto max-w-7xl grid grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 lg:pt-16">
                        <h1 className="sr-only">Checkout</h1>

                        <section
                            aria-labelledby="summary-heading"
                            className="bg-gray-800 py-12 text-gray-300 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pb-24 lg:pt-0"
                        >
                            <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                                <h2 id="summary-heading" className="sr-only">
                                    Order summary
                                </h2>

                                <dl>
                                    <dt className="text-sm font-medium">Amount due</dt>
                                    <dd className="mt-1 text-3xl font-bold tracking-tight text-white">${total.toFixed(2)}</dd>
                                    <dd className="text-xs font-medium text-white">{totalInSats.toLocaleString()} sats</dd>
                                </dl>

                                <ul role="list" className="divide-y divide-white divide-opacity-10 text-sm font-medium">
                                    {Array.isArray(cart) && cart.map((product, index) => (
                                        <li key={index} className="flex items-start space-x-4 py-6">
                                            <img
                                                src={product.ImageURLs[0]}
                                                alt={product.Name}
                                                className="h-20 w-20 flex-none rounded-md object-cover object-center"
                                            />
                                            <div className="flex-auto space-y-1">
                                                <h3 className="text-white">{product.Name}</h3>
                                                <p>{product.color}</p>
                                                <p>{product.size}</p>
                                                <p>Quantity: {product.quantity}</p>
                                                <p>Price per unit: ${product.PriceInDollars?.toFixed(2)} USD</p>
                                                <p className="text-sm text-gray-500">{product.PriceInSatoshis?.toLocaleString()} sats</p>
                                            </div>
                                            <div>
                                                <p className="flex-none text-base font-medium text-white">${(product.PriceInDollars * (product.quantity || 1)).toFixed(2)} USD</p>
                                                <p className="flex-none text-sm text-gray-500">{(product.PriceInSatoshis * (product.quantity || 1)).toLocaleString()} sats</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <dl className="space-y-6 border-t border-white border-opacity-10 pt-6 text-sm font-medium">
                                    <div className="flex items-center justify-between">
                                        <dt>Subtotal</dt>
                                        <div className='justify-right'>
                                            <dd>${subtotal.toFixed(2)}</dd>
                                            <dd className="text-sm text-gray-500">{subtotalInSats.toFixed(0).toLocaleString()} sats</dd>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <dt>Taxes</dt>
                                        <div>
                                            <dd>${totalTaxes.toFixed(2)}</dd>
                                            <dd className="text-sm text-gray-500">{totalTaxesInSats.toFixed(0).toLocaleString()} sats</dd>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white">
                                        <dt className="text-base">Total</dt>
                                        <div>
                                            <dd className="text-base">${total.toFixed(2)}</dd>
                                            <dd className="text-sm text-gray-500">{totalInSats.toFixed(0).toLocaleString()} sats</dd>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </section>

                        <div className='mt-4 lg:col-start-1 lg:row-start-1'>
                            <button
                                onClick={handleBackClick}
                                className="ml-8 mb-4 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                            >
                                Back to Store
                            </button>

                            <section
                                aria-labelledby="payment-and-shipping-heading"
                                className="py-16 lg:mx-auto lg:w-full lg:max-w-lg lg:pb-24 lg:pt-0"
                            >
                                <h2 id="payment-and-shipping-heading" className="sr-only">
                                    Payment and shipping details
                                </h2>

                                <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                                    <div className="bg-white rounded-xl shadow-lg p-8">
                                        {!isFormSubmitted ? (
                                            <ProductOrderForm
                                                onOrderSubmitted={handleOrderSubmit}
                                                isDigitalProduct={isDigitalOnly}
                                            />
                                        ) : (
                                            <>
                                                <CartPayment
                                                    cart={cart}
                                                    user={user}
                                                    totalInSats={totalInSats.toFixed(0)}
                                                    orderDetails={orderDetails}
                                                    handlePaymentSuccess={handlePaymentSuccess}
                                                />
                                                {/* <ProductOrderFormDisplay
                                                    onModify={handleModify}
                                                    details={orderDetails}
                                                    isDigitalProduct={isDigitalOnly}
                                                /> */}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-10 mb-4 text-xs text-center text-slate-500 py-2 lg:col-span-2">Powered by <a href="https://paywithflash.com" className="font-bold text-slate-500">Flash</a></div>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};

export default ProductGalleryCheckoutPage;
