import React, { useEffect, useState, useRef } from 'react';
import QRCode from 'qrcode.react';
import { SimplePool } from 'nostr-tools';
import { useLocation } from 'react-router-dom';
import {  PencilSquareIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

import PayWithFlashButton from '../components/payWithFlash/payWithFlashButton';
import LnInvoiceHashDisplayAndCopy from '../components/paymentComponents/LnInvoiceHashDisplayAndCopy';
import { TbChevronsDownLeft } from 'react-icons/tb';


const UrlPaymentPage = () => {
  const [flashId, setFlashId] = useState('');
  const [flashDetails, setFlashDetails] = useState({});
  const [nwcUrl, setNwcUrl] = useState('');
  const [priceInDollars, setPriceInDollars] = useState('');
  const [priceInSats, setPriceInSats] = useState('');
  const [bitcoinPrice, setBitcoinPrice] = useState('');
  const [isPaid, setIsPaid] = useState(false);
  const [isEditing, setIsEditing] = useState (false);
  const [isLoadingColumn1, setIsLoadingColumn1] = useState(true);
  const [isLoadingColumn2, setIsLoadingColumn2] = useState(true);
  const [invoice, setInvoice] = useState('');
  const [userPrivateKey, setUserPrivateKey] = useState('');
  const [userPublicKey, setUserPublicKey] = useState('');
  const [userMetadata, setUserMetadata] = useState('');
  const [userFee, setUserFee] = useState();

  // Referral Data
  const [isReferralExists, setIsReferralExists] = useState();
  const [referralData, setReferralData] = useState();
  const [referralWalletData, setReferralWalletData] = useState();

  const [loading, setLoading] = useState (false);

  const [payerUserPublicKey, setPayerUserPublicKey] = useState('') || "";
  const [payerSelectedWallet, setPayerSelectedWallet] = useState({});
  
  const handleEditingPrice = () => {
    setIsEditing (true);

    setTimeout (() => {
      if (inputRef.current) {
        inputRef.current.focus ();
      }
    }, 20);
  }

  const handleChange = (e) => {
    setCurrentPrice (e.target.value);
    setFlashDetails ({...flashDetails, 'price' : e.target.value});
  }

  const handleBlur = async (e) => {
    setIsEditing (false);
    setLoading (true);
    if (nwcUrl) {
      const { priceInDollars, priceInSats } = await convertPrice(flashDetails.price, flashDetails.currency);
      if (!priceInDollars || !priceInSats) {
        console.error('Failed to convert price');
        return;
      }
      setPriceInDollars(priceInDollars);
      setPriceInSats(priceInSats);

      const createdInvoice = await createZapInvoice(userPublicKey, nwcUrl, priceInSats, 'Payment for ' + flashDetails.name);
      if (!createdInvoice) {
        console.error('Failed to create invoice');
        return;
      }
      setInvoice(createdInvoice.invoice);
      setLoading (false);
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const flashIdFromURL = queryParams.get('flashId');
    if (flashIdFromURL) {
      setFlashId(flashIdFromURL);
    }
  }, [location]);


  // Function to fetch the user metadata
  const fetchUserMetadata = async (publicKey) => {
    const relayUrls = ["wss://relay.paywithflash.com", "wss://relay.damus.io", "wss://relay.primal.net", "wss://relay.snort.social", "wss://relay.nostr.band"];
    const pool = new SimplePool();

    const filters = [{
      pubkey: publicKey,
      kinds: [0]
    }];

    try {
      const events = await pool.list(relayUrls, filters);

      const userMatchingEvents = events.filter(event => event.pubkey === publicKey);
      if (userMatchingEvents.length > 0) {
        // Process the array of matching events
      } else {
        console.log('No events found with the matching public key.');
      }

      // Sort the events by the 'created_at' timestamp in descending order
      const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);

      // Find the first event with non-empty content
      const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");
      if (metadataEvent) {
        const metadata = JSON.parse(metadataEvent.content);
        // Store user metadata in variables
        const userName = metadata.name;
        const userPictureUrl = metadata.picture;
        const userAbout = metadata.about;

        return { userName, userPictureUrl, userAbout };
      } else {
        console.log('No metadata found for the given public key.');
        return { 
          userName: 'Unknown User', 
          userPictureUrl: 'default_picture_url', // Replace with a default picture URL
          userAbout: 'No description available' 
        };
      }
    } catch (error) {
      console.error('An error occurred while fetching user metadata:', error);
      throw error;
    }
  };

  // Function to get the Flash details from db using the flashID from requested url
  async function fetchFlashById(flashId) {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/get_flash_from_id?flash_id=${encodeURIComponent(flashId)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
  
  

  // Function to get the nwc url from the db using the nwc_id
  async function fetchWalletUrlById(wallet_id) {
    try {
      const url = new URL('https://api.paywithflash.com/api/get_wallet_from_id');
      url.searchParams.append('wallet_id', wallet_id);
  
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrowing the error for handling it further up in the call stack
    }
  }
  const fetchUserData = async(user_public_key) => {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/get_user_by_public_key?user_public_key=${encodeURIComponent(user_public_key)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  // Function to get the referral data from the db using user public key
  async function fetchReferralDataWithPublicKey(userPublicKey) {
    const formData = new FormData ();
    formData.append('userPublicKey', userPublicKey);
    try {

      const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrowing the error for handling it further up in the call stack
    }
  }


  // Function to decrypt contents using the user public key
  async function decryptContents(userPublicKey, encryptedProperties) {
    try {
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/decrypt_content', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userPublicKey: userPublicKey,
          encryptedProperties: encryptedProperties
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Failed to decrypt config');
      }
  
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrowing the error for handling it further up in the call stack
    }
  }


  // Function to get the price of Bitcoin
  async function getBitcoinPrice() {
    try {
      const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST', // if the API requires POST method
        headers: {
          'Content-Type': 'application/json',
        },
        // If the API requires a body payload, uncomment and edit the following line
        // body: JSON.stringify({ key: 'value' }), 
      });
  
      if (!response.ok) {
        // If the response is not OK, throw an error with the response status
        throw new Error(`API call failed with status: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Assuming the API returns an object with a property that contains the Bitcoin price
      return data
    } catch (error) {
      console.error('API call failed:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }


  // Function to convert the price from currency in order to have both satoshis and usd values
  async function convertPrice(price, currency) {
    const oneBitcoinInSats = 100000000; // 100 million satoshis in one bitcoin
    let priceInSats, priceInDollars;
  
    try {
      const bitcoinPrice = await getBitcoinPrice();
      setBitcoinPrice(bitcoinPrice)
      setBitcoinPrice(bitcoinPrice)
  
      if (!bitcoinPrice) {
        throw new Error('Failed to fetch Bitcoin price');
      }
  
      if (currency === "Dollar") {
        const amountInUSD = parseFloat(price);
        priceInSats = Math.round((amountInUSD / bitcoinPrice) * oneBitcoinInSats);
        priceInDollars = amountInUSD;
        setPriceInSats(priceInSats)
        setPriceInDollars(priceInDollars)
      } else if (currency === "Satoshis") {
        const amountInSats = parseFloat(price);
        priceInSats = amountInSats;
        priceInDollars = ((amountInSats / oneBitcoinInSats) * bitcoinPrice).toFixed(2);
        setPriceInSats(priceInSats)
        setPriceInDollars(priceInDollars)
      } else {
        throw new Error('Unknown currency type: ' + currency);
      }
  
      return { priceInDollars, priceInSats };
    } catch (error) {
      console.error('Error in conversion:', error);
      throw error;
    }
  }


  // Function used to create the Zap Invoice
  async function createZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) {
    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }
    // const data = "lnbc10u1pj6j4gzpp5tzam0ynwcfv3prxmlj59lg40ssw3qfdyceeq0kzsmdg7vrg9kejqdpv2pshjmt9de6zqen0wgsy67fq2pshjmt9de6zqnrfde4scqzzsxqyz5vqsp5nmgtcgn3lnw2amw7rugnmtrdu9mfcw7kend7n3z5mk8rnh2symps9qyyssqvg8604jgpkc8eyknyekr5l8gyu8glclq09xdk8fcjep2jlj5cgrr53uc5lsmlsepe50hgvtgpdjd8v7w3xwdc7qwv02yq2rph9v4ulqqt69u8p"
    // const data = "lnbc10u1pj6j4gzpp5tzam0ynwcfv3prxmlj59lg40ssw3qfdyceeq0kzsmdg7vrg9kejqdpv2pshjmt9de6zqen0wgsy67fq2pshjmt9de6zqnrfde4scqzzsxqyz5vqsp5nmgtcgn3lnw2amw7rugnmtrdu9mfcw7kend7n3z5mk8rnh2symps9qyyssqvg8604jgpkc8eyknyekr5l8gyu8glclq09xdk8fcjep2jlj5cgrr53uc5lsmlsepe50hgvtgpdjd8v7w3xwdc7qwv02yq2rph9v4ulqqt69u8p"
    return data;
  }


  // Function used to send the Zap Invoice to the user and request payment via webln
  async function requestWebLnPayment(invoice) {
    if (typeof window.webln === "undefined") {
      throw new Error("No WebLN available. Get a bitcoin lightning wallet. We recommend www.getalby.com");
    }

    try {
      await window.webln.enable();
      const result = await window.webln.sendPayment(invoice);
      return result;
    } catch (error) {
      console.error("Failed to pay invoice:", error);
    }
  }


  // Function to display the QR code of the invoice
  async function showQRCode(invoice) {
    // Load the QR code library
    try {
      await new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/qrcode-generator/1.4.4/qrcode.min.js";
        script.onload = resolve;
        script.onerror = () => reject(new Error('Error loading QRCode library'));
        document.head.appendChild(script);
      });
    } catch (error) {
      console.error(error.message);
      return; // Stop the execution of the function if the import fails
    }
  
    // Ensure qrcode function is available globally
    if (typeof qrcode !== 'function') {
      console.error('qrcode function is not available.');
      return;
    }
  
    // Create QR Code instance
    let qr = qrcode(0, 'H'); // Error correction level: 'H'
    qr.addData(invoice);
    qr.make();
  
    // Create an image tag for the QR code
    let imgTag = qr.createImgTag(4); // Cell size: 4
  
    // Append the image tag to the element with ID 'qrcode'
    document.getElementById('qrcode').innerHTML = imgTag;
  }
  
  


  // Function to check if the invoice was paid
  async function checkInvoicePayment(encryptedUserNostrWalletConnectUrl, invoice) {
    const startTime = Date.now();
    let isPaid = false;

    while (Date.now() - startTime < 300000) { // Loop for max 5 minutes
      try {
        const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/check_invoice_payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            userPublicKey: userPublicKey,
            encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
            invoice: invoice
          }), // Assuming nostrWalletConnectUrl is handled server-side
        });
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to check payment');
        }

        if (data.is_paid) {
          isPaid = true;
          break; // Exit the loop if the invoice is paid
        }

        // If not paid, wait 1 second before trying again
        await new Promise(resolve => setTimeout(resolve, 1000));

      } catch (error) {
        console.error("Error checking invoice payment:", error);
        // You might want to decide if you should throw an error or perhaps continue trying
        // For now, we'll wait 1 second before trying again
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }

    return isPaid;
  }


  // Function to save the transaction to table transactions of the db
  async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
    const formData = new FormData();
    formData.append('from_public_key', from_public_key);
    formData.append('to_public_key', to_public_key);
    formData.append('transaction_date', transactionDate);
    formData.append('amount_in_sats', amountInSats);
    formData.append('value_in_usd', valueInUsd);
    formData.append('flash_id', flashId);
    formData.append('flash_name', flashName);
    formData.append('flash_type', flashType); // Assuming this is also a new requirement
    formData.append('to_wallet_id', toWalletId);
    formData.append('to_user_wallet_name', toUserWalletName);
    formData.append('from_wallet_id', fromWalletId);
    formData.append('from_user_wallet_name', fromUserWalletName);
    formData.append('lnurl', lnurl);

    try {
      const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
          method: 'POST',
          body: formData,
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to save transaction to database');
      }

      const successData = await response.json();
      console.log('Transaction successfully saved to database:', successData);

      return successData;
    } catch (error) {
      console.error('Error saving transaction to database:', error);
      throw error;
    }
  }



  // Function to save the fee transaction to the database
  async function saveFeeTransactionToDB(
    invoice,
    priceInSats,
    priceInDollars,
    flashId,
    userPublicKey
  ) {
    const transactionDate = new Date().toISOString();

    const formData = new FormData();
    formData.append('lnurl', invoice);
    formData.append('amount_in_sats', priceInSats);
    formData.append('value_in_usd', priceInDollars);
    formData.append('flash_id', flashId);
    formData.append('transaction_date', transactionDate);
    formData.append('user_public_key', userPublicKey);

    try {
        let response = await fetch('https://api.paywithflash.com/api/save_transaction_to_db', {
            method: 'POST',
            body: formData,
        });

        const data = await response.json(); // Define data here

        if (!response.ok) {
            throw new Error(data.message || 'Failed to save transaction to database');
        }

        console.log('Transaction successfully saved to database:', data); // Use data here
    } catch (error) {
        console.error('Error saving transaction to database:', error);
    }
  }


  // The function used to for the user to pay the fee owed to Flash
  async function payFeeToFlash(encryptedUserNostrWalletConnectUrl, amount, memo) {

    const transactionDate = new Date().toISOString();

    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        amount_in_sats: priceInSats,
        value_in_usd: priceInDollars,
        usd_btc_exchange_rate: bitcoinPrice,
        flash_id: flashId,
        transaction_date: transactionDate,
        user_public_key: flashDetails.user_public_key
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }

    return data;
  }

  // The function used to for the user to pay the fee owed to referred him
  async function payFeeToReferral(encryptedReferralUserNostrWalletConnectUrl, encryptedUserNostrWalletConnectUrl, amount, memo) {

    const transactionDate = new Date().toISOString();
    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_referral', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        referralUserPublicKey: referralData.user_public_key,
        encryptedReferralUserNostrWalletConnectUrl: encryptedReferralUserNostrWalletConnectUrl,
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        amount_in_sats: priceInSats,
        value_in_usd: priceInDollars,
        usd_btc_exchange_rate: bitcoinPrice,
        flash_id: flashId,
        transaction_date: transactionDate,
        user_public_key: flashDetails.user_public_key
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }

    return data;
  }


  // The function used to create the Zap Invoice
  async function payUserMinusFee(encryptedUserNostrWalletConnectUrl, amount, memo) {
    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_user_minus_fee', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        lnAddress: lnAddress
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }

    return data.invoice;
  }



  
  // useEffect for fetching flash details and initializing payment process
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingColumn1(true);
      setIsLoadingColumn2(true);
      setIsLoadingColumn1(true);
      setIsLoadingColumn2(true);
      try {
        const flashData = await fetchFlashById(flashId);
        if (!flashData) {
          console.error('Failed to fetch flash data');
          return;
        }
        setFlashDetails(flashData);
        setCurrentPrice (flashData.price);
        setCurrentPrice (flashData.price);
        setUserPublicKey(flashData.user_public_key);

        const userData = await fetchUserData(flashData.user_public_key);
        setUserFee(userData.fee);
  
        const referralData = await fetchReferralDataWithPublicKey(flashData.user_public_key);
        setIsReferralExists (referralData.isReferralExists);
        setReferralData(referralData.referralData);

        if (referralData.isReferralExists) {
          // speical processing for parallel economy
          if (!referralData.referralData.wallet_id) {
            const referralWalletData = {};
            referralWalletData.nwc_url = encryptedParallelEConomyNwcUrl;
            setReferralWalletData(referralWalletData);
          }
          else {
              const referralWalletData = await fetchWalletUrlById(referralData.referralData.wallet_id);
              setReferralWalletData (referralWalletData);
          }
        }
        const walletData = await fetchWalletUrlById(flashData.wallet_id);
        if (!walletData) {
          console.error('Failed to fetch Wallet NWC URL');
          return;
        }
        setWalletData(walletData);
        setWalletData(walletData);
        
        // Set nwcUrl based on walletData
        if (walletData.nwc_url !== "") {
          setNwcUrl(walletData.nwc_url);  // Setting nwcUrl here
        } else {
          setNwcUrl("FlashNWC");
        }
        
        // Only proceed if nwcUrl is set
        if (nwcUrl) {
            const { priceInDollars, priceInSats } = await convertPrice(flashData.price, flashData.currency);
            if (!priceInDollars || !priceInSats) {
              console.error('Failed to convert price');
              return;
            }
            setPriceInDollars(priceInDollars);
            setPriceInSats(priceInSats);

            const createdInvoice = await createZapInvoice(userPublicKey, nwcUrl, priceInSats, 'Payment for ' + flashData.name);
            if (!createdInvoice) {
              console.error('Failed to create invoice');
              return;
            }
            setInvoice(createdInvoice.invoice);
        }
      } catch (error) {
        console.error('Error in fetching data:', error);
      }
      // setIsLoading(false);
      // setIsLoading(false);
    };

    if (flashId) {
      fetchData();
    }
  }, [flashId, nwcUrl]); // Dependency array includes flashId

  useEffect(() => {
    if (invoice) {
      showQRCode(invoice).catch(console.error);
      setIsLoadingColumn2(false);
      setIsLoadingColumn2(false);
      checkPaymentStatus(nwcUrl, invoice);
    }
  }, [invoice]);


  useEffect(() => {
    const fetchMetadata = async () => {
      if (userPublicKey) {
        try {
          const userMetadata = await fetchUserMetadata(flashDetails.user_public_key);
          setUserMetadata(userMetadata);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        } finally {
          setIsLoadingColumn1(false); // Set loading to false only after fetching metadata
        }
      }
    };
  
    if (userPublicKey) {
      fetchMetadata();
    }
  }, [userPublicKey]);
  


  useEffect(() => {
    const fetchMetadata = async () => {
      if (userPublicKey) {
        try {
          const userMetadata = await fetchUserMetadata(flashDetails.user_public_key);
          setUserMetadata(userMetadata);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        } finally {
          setIsLoadingColumn1(false); // Set loading to false only after fetching metadata
        }
      }
    };
  
    if (userPublicKey) {
      fetchMetadata();
    }
  }, [userPublicKey]);
  
  

  // Checks the payment status of the invoice
  const checkPaymentStatus = async (nwcUrl, invoice) => {
    const paymentStatus = await checkInvoicePayment(nwcUrl, invoice);
    if (paymentStatus) {
      // Payment success: set isPaid to true
      handlePaymentSuccess(nwcUrl, invoice)
      // Payment success: set isPaid to true
      handlePaymentSuccess(nwcUrl, invoice)
    }
  };

  // Handles payment via extension
  const handlePaymentClick = async () => {
    try {
      await requestWebLnPayment(invoice);
      // Payment status is already being checked in the background
    } catch (error) {
      console.error('Error in handling payment:', error);
    }
  };


  // Function to save the transaction to the database
  async function saveFeeTransactionToDB(
    lnurl
  ) {
    const transactionDate = new Date().toISOString();

    const formData = new FormData();
    formData.append('lnurl', lnurl);
    formData.append('amount_in_sats', priceInSats);
    formData.append('value_in_usd', priceInDollars);
    formData.append('usd_btc_exchange_rate', bitcoinPrice);
    formData.append('flash_id', flashId);
    formData.append('transaction_date', transactionDate);
    formData.append('user_public_key', flashDetails.user_public_key);

    try {
        let response = await fetch('https://api.paywithflash.com/api/save_fee_transaction_to_db', {
            method: 'POST',
            body: formData,
        });

        const data = await response.json(); // Define data here

        if (!response.ok) {
            throw new Error(data.message || 'Failed to save fee transaction to database');
        }

        console.log('Fee transaction successfully saved to database:', data); // Use data here
    } catch (error) {
        console.error('Error saving fee transaction to database:', error);
    }
  }

  // Function to handle successful payment
  const handlePaymentSuccess = async () => {
    setIsPaid(true);

    const transactionDate = new Date().toISOString();
    saveTransactionToDB("", flashDetails.user_public_key, transactionDate, priceInSats, priceInDollars, flashId, flashDetails.name, "Payment Link", walletData.id, walletData.user_wallet_name, 0, "", invoice)

    // Calculate the fee and price minus fee
    const feePercentage = userFee; // 1%

    // Assuming priceInSats is already defined and is the total price in Satoshis
    let satsFee = priceInSats * (feePercentage / 100); // Calculate the fee
    satsFee = Math.round(satsFee); // Rounding to the nearest whole number

    let satsFeeToFlash, satsFeeToReferral;
    if (isReferralExists) {
      const payPercent = referralData.pay_percent;
      satsFeeToFlash = satsFee * (100 - payPercent) / 100;
      satsFeeToReferral = satsFee * payPercent / 100;
      await payFeeToReferral(referralWalletData.nwc_url, nwcUrl, satsFeeToReferral, `Referral Fee for flash id: ${flashId}`);
    }
    else satsFeeToFlash = satsFee;
    // Pay fee to flash
    await payFeeToFlash(nwcUrl, satsFeeToFlash, `Fee for flash id: ${flashId}`);

  };


  // handlePaymentClick function handles web extension payment and sets hasPaidWithExtension to true
  const handlePayWithFlashSuccess = async () => {
    try {
       // Get current date
      const currentDate = new Date().toISOString();

      await saveTransactionToDB(payerUserPublicKey, flashDetails.user_public_key, currentDate, priceInSats, priceInDollars, flashId, flashDetails.name, "Payment Link", walletData.id, walletData.user_wallet_name, payerSelectedWallet.id, payerSelectedWallet.user_wallet_name, invoice)

      // Calculate the fee and price minus fee
      const feePercentage = 1; // 1%

      // Assuming priceInSats is already defined and is the total price in Satoshis
      let satsFee = priceInSats * (feePercentage / 100); // Calculate the fee
      satsFee = Math.round(satsFee); // Rounding to the nearest whole number
      
      // Pay fee to flash
      const response = await payFeeToFlash(nwcUrl, satsFee, `Fee for flash id: ${flashId}`);
      const fee_invoice = response.invoice;
      const isPaid = response.is_paid;
      
      // Update UI state at the end
      setIsPaid(true);
    } catch (error) {
        console.error('Error in Pay With Flash', error);
    }
  };


  // Function to get the price of Bitcoin
  async function getBitcoinPrice() {
    try {
        const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
            method: 'POST', // if the API requires POST method
            headers: {
                'Content-Type': 'application/json',
            },
            // If the API requires a body payload, uncomment and edit the following line
            // body: JSON.stringify({ key: 'value' }), 
        });

        if (!response.ok) {
            // If the response is not OK, throw an error with the response status
            throw new Error(`API call failed with status: ${response.status}`);
        }

        const data = await response.json();

        // Assuming the API returns an object with a property that contains the Bitcoin price
        return data
    } catch (error) {
        console.error('API call failed:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
}


  // Function to go through the plans and make sure we have two columns: price in sats & price in dollars
  async function convertPrices(currency, value) {
    try {
        const bitcoinPrice = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars

        let convertedPrice;

        if (currency === "usd") {
            const priceInDollars = value / 100000000; // Convert satoshis to bitcoin
            convertedPrice = (priceInDollars * bitcoinPrice).toFixed(2);
        } else if (currency === "satoshis") {
            convertedPrice = value;
        }
        return convertedPrice;

    } catch (error) {
        console.error('Conversion failed:', error);
        return null; // Return null in case of error
    }
  }

  


  const SkeletonLoaderColumn1 = () => {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="w-12 h-12 bg-gray-300 rounded-full mb-4 animate-pulse"></div> {/* Skeleton Image */}
        <div className="w-3/4 h-2 bg-gray-300 mb-2 animate-pulse"></div> {/* Skeleton Text */}
        <div className="w-3/4 h-2 bg-gray-300 mb-2 animate-pulse"></div> {/* Skeleton Text */}
      </div>
    );
  };

  const SkeletonLoaderColumn2 = () => {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="w-64 h-64 bg-gray-300 rounded-full mb-4 animate-pulse"></div> {/* Skeleton Image */}
      </div>
    );
  };


  return (
    <>
      { loading ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-0">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <div className= "h-screen bg-gray-100 flex flex-col justify-center items-center px-4 bg-gray">
          {/* Small Flash Logo Centered */}
          <img src="https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/flash-logo-dark.png" alt="Flash Logo" className="h-10 w-auto mb-5 self-center" />

          <div className="w-full sm:w-full md:w-2/3 lg:w-1/3 p-8 shadow-sm rounded-2xl bg-white">
            {!isPaid ? (
              <>
                {/* Client Logo and Name Centered */}
                <div className="text-center mb-4">
                  {isLoadingColumn1 ? (
                    <SkeletonLoaderColumn1 />
                  ) : (
                    <div className="flex flex-col items-center">
                      <img className="rounded-full mb-2 border border-gray-300" src={userMetadata.userPictureUrl} alt="Profile Image" style={{ width: '60px', height: '60px' }} />
                      <div className="text-gray-700">
                        <h2 className="text-md font-semibold">{userMetadata.userName}</h2>
                        <p className="text-sm">{userMetadata.userAbout}</p>
                      </div>
                    </div>
                  )}
                </div>

                {/* QR Code Centered */}
                <div className="text-center mb-2 mt-2">
                  {isLoadingColumn2 ? (
                    <SkeletonLoaderColumn2 />
                  ) : (
                    <div className="inline-block" style={{ width: '180px', height: '180px' }}>
                        <QRCode
                            value={invoice}
                            size={180}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                            renderAs={"svg"}
                            imageSettings={{
                                src: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png",
                                excavate: true,
                                height: 40,
                                width: 40
                            }}
                        />
                    </div>
                  )}
                </div>

                {/* Payment Information and Buttons Centered */}
                <div className="text-center mb-0">
                  <div className="flex justify-between items-center mb-4 mt-3">
                    <span className="text-md font-medium text-gray-700">Price:</span>
                    <span className="flex flex-row text-md font-bold text-gray-800">
                        {!isEditing && 
                          <>
                            <span>${parseFloat(priceInDollars).toLocaleString()}{' '}({parseInt(priceInSats).toLocaleString()} sats){' '}</span>
                            <PencilSquareIcon onClick = {handleEditingPrice}className="h-6 w-6 cursor-pointer" aria-hidden="true"></PencilSquareIcon>
                          </>
                        }
                        {isEditing && 
                          <input type='number' ref={inputRef} className="mx-2 rounded-md" value={parseInt(currentPrice)} onChange={handleChange} onBlur={handleBlur}></input>
                        }
                    </span>
                  </div>
                  <LnInvoiceHashDisplayAndCopy value={invoice} />
                  <div className="flex justify-center space-x-2 mt-3">
                    {/* <div className="w-1/2">
                    <PayWithFlashButton flashDetails={flashDetails} onPaymentSuccess={handlePayWithFlashSuccess} payerSelectedWallet={payerSelectedWallet} setPayerSelectedWallet={setPayerSelectedWallet} payerUserPublicKey={payerUserPublicKey} setPayerUserPublicKey={setPayerUserPublicKey} className="w-full text-lg py-3 bg-gradient-to-r from-purple-600 to-purple-500 hover:from-purple-700 hover:to-purple-600"/>
                    </div> */}
                    <div className="w-1/2">
                      <button className="text-md text-white font-bold px-4 py-3 rounded cursor-pointer bg-blue-600 hover:bg-blue-700 w-full" onClick={handlePaymentClick}>Pay with Extension</button>
                    </div>
                  </div>
                </div>

                {/* Footer section Centered */}
                <div className="mt-6 mb-0 text-sm text-center">
                  <span className="text-gray-700 mr-1">The easiest way to pay with BTC online</span>
                  <a href="https://paywithflash.com/" className="text-blue-600 hover:text-blue-800">→ Discover Flash</a>
                </div>
              </>
            ) : (
              <p>Payment successful! Thank you.</p>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default UrlPaymentPage;